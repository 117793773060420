import { Box, Button, Divider, FormLabel, Grid, MenuItem, Select, TextField, Typography } from "@mui/material"
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import { useContext, useEffect, useState } from "react";
import { getCreatedBy, getDefectList, postDefect, updateDefect, uploadAttachment } from "../../api/apiCalls";
import { decode } from "html-entities";
import ReactQuill from "react-quill";
import DynamicAutocomplete from "../Lists/dropDownList";
import { AttachemntListComp } from "../attachments/attachments";
import CustomizedSnackbars from "../snackBar/simpleSnackBar";
import { tabContext } from "../../Context/TabContext";

export let DefectContent = (props) => {
    const [edit, setEdit] = useState(false);
    let [nDefectDetails, setNDefectDetails] = useState<any>('');
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState('');
    let [attachmentList, setAttachmentList] = useState<any>([])
    const [errorState, setErrorState] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [severity, setSeverity] = useState('')
    const { tabObject, setTabObject } = useContext(tabContext)

    let handleEdit = () => setEdit(true);
    let cancelEdit = () => setEdit(false);
    const handleError = (errMessage: string, errorSeverity: string) => {
        setErrorState(true);
        setErrorMessage(errMessage);
        setSeverity(errorSeverity);
    };

    const setFalseMessage = async () => {
        setErrorState(false);
        setErrorMessage('');
    }
    const handleFileChange = (event) => {
        try {
            if (event.target.files[0]) {
                setFile(event.target.files[0]);
                setFileName(event.target.files[0].name);
            }
        }
        catch (err) {
            console.error('Error fetching data:', err);
            setErrorState(true)
            setSeverity('error')
            setErrorMessage(err.response.data.error)
        }

    };
    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
    let handleDelete = async (attachmentId) => {
        try {
            let nattachment = [...attachmentList]
            nattachment = attachmentList.filter(item => item.attachmentId !== attachmentId);
            let pushData = {}
            pushData['attachment'] = nattachment

            let updateTEAttach = await updateDefect(nDefectDetails['_id'], pushData)
            if (updateTEAttach.success) {
                setAttachmentList(nattachment)
            }
        }
        catch (err) {
            setErrorState(true)
            setSeverity('error')
            setErrorMessage(err.response.data.error)
        }


    }
    const handleUpload = async () => {
        try {
            if (file) {
                const formData = new FormData();
                formData.append('file', file);
                let pushData = {}

                let attachList = []
                if (attachmentList) {
                    attachList = [...attachmentList]
                }
                let returnResponse = await uploadAttachment(formData)
                if (returnResponse.status === 201) {
                    let nattachment = { 'attachmentId': returnResponse.data.fileID, 'name': file.name }
                    attachList.push(nattachment)
                    pushData['attachment'] = attachList
                    let updateTEAttach = await updateDefect(nDefectDetails['_id'], pushData)
                    if (updateTEAttach.success) {
                        setAttachmentList(attachList)
                        setFileName('Upload Success')
                        await sleep(1000)
                        setFile(null)
                    }
                }
            }
        }
        catch (err) {
            console.error('Error fetching data:', err);
            setErrorState(true)
            setSeverity('error')
            setErrorMessage(err.response.data.error)
        }
    };
    let handleSave = async () => {
        try {
            if (!props.defectId.includes('newDefect')) {
                // Save logic here
                let sendVal = {...nDefectDetails}
                delete sendVal['assignedTo']
                delete sendVal['createdBy']
                delete sendVal['updatedBy']
                delete sendVal['__v']
                await updateDefect(nDefectDetails['_id'], sendVal)
                setEdit(false);
                console.log(nDefectDetails)
            }
            else {
                console.log(nDefectDetails)
                let savedata = await postDefect(nDefectDetails)
                let tabs = tabObject;

                if (!tabs.some(el => el.id === `${savedata.id}`)) {
                    let ntabs = [...tabs]
                    for (let ntab of ntabs) {
                        ntab['active'] = false
                    }
                    ntabs = await ntabs.filter((tab) => tab.id !== props.tabid)
                    await setTabObject([
                        ...ntabs,
                        {
                            id: `${savedata.defectId}`,
                            title: `${savedata.defectId}`,
                            active: true,
                            selectedModule: 5
                        },
                    ]);
                }
                setEdit(false);
            }

        }
        catch (err) {
            console.error('Error fetching data:', err);
            setErrorState(true)
            setSeverity('error')
            setErrorMessage(err.response.data.error)
        }

    };

    let handleFieldChange = (field, value, isDefault,editor?) => {
        try {
            if(editor)
            {
                //alert(JSON.stringify(editor.getContents()))
                value = JSON.stringify(editor.getContents())
            }
            // Create a copy of the current state of nDefectDetails
            let newDefectDetails = { ...nDefectDetails };

            // Loop logic: Check if the field should be added to dynamicFields
            if (!isDefault) {
                // Initialize dynamicFields if it doesn't exist
                if (!newDefectDetails.dynamicFields) {
                    newDefectDetails.dynamicFields = {};
                }
                // Update dynamicFields with the new field and value
                newDefectDetails.dynamicFields[field] = value;
            } else {
                // If it's a default field, update it directly in nDefectDetails
                newDefectDetails[field] = value;
            }

            // Set the updated nDefectDetails
            setNDefectDetails(newDefectDetails);
        }
        catch (err) {
            console.error('Error updating field:', err);
            setErrorState(true);
            setSeverity('error');
            setErrorMessage(err?.response?.data?.error || "An unexpected error occurred");
        }
    };



    let getSelDefectDetails = async () => {
        try {
            if (!props.defectId.includes('newDefect')) {
                let defectDetails = await getDefectList(`defectId=${props.defectId}`);
                if (defectDetails[0]['createdBy']) {
                    defectDetails[0]['createdBy'] = await getCreatedBy(defectDetails[0]['createdBy']);
                }
                if (defectDetails[0]['updatedBy']) {
                    defectDetails[0]['updatedBy'] = await getCreatedBy(defectDetails[0]['updatedBy']);
                }
                if (defectDetails[0]['assignedTo']) {
                    defectDetails[0]['assignedTo'] = await getCreatedBy(defectDetails[0]['assignedTo']);
                }
                if (!defectDetails[0]['dynamicFields']) {
                    defectDetails[0]['dynamicFields'] = {}
                }
                setNDefectDetails(defectDetails[0]);
                if (defectDetails[0]['attachment']) {
                    setAttachmentList(defectDetails[0]['attachment'])
                }
            }
            else {
                setEdit(true)
            }
        }
        catch (err) {
            console.error('Error fetching data:', err);
            setErrorState(true)
            setSeverity('error')
            setErrorMessage(JSON.stringify(err))
        }
    };

    let fetchData = () => {
        getSelDefectDetails();
    };
    let setnUser = (userName) => {
        if (!props.defectId.includes('newDefect')) {
            handleFieldChange('assignedTo', userName['label'], true)
        }
        else {
            handleFieldChange('assignedTo', userName['value'], true)
        }
    }

    useEffect(() => { fetchData(); }, []);

    return (

        <Grid container>
            <CustomizedSnackbars errorMessage={errorMessage} refresh={errorState} closeError={setFalseMessage} severity={severity} />
            <Grid item xs={8}>
                {!edit ? (
                    <Typography variant="h6" color={'#1F618D'}>
                        {nDefectDetails['defectId']} : {nDefectDetails['summary']}
                    </Typography>
                ) : (
                    <Typography variant="h6" color={'#1F618D'}>
                        <b>{nDefectDetails['defectId']}</b>
                    </Typography>
                )}
            </Grid>
            <Grid item xs={4}>
                <Box display="flex" justifyContent="flex-end">
                    <Button variant="contained" disabled={edit} onClick={handleEdit} sx={{ ml: 1, bgcolor: '#196db6' }}>
                        <EditIcon />
                    </Button>
                    <Button variant="contained" disabled={!edit} onClick={handleSave} sx={{ ml: 1, bgcolor: '#1ABC9C' }}>
                        <SaveIcon />
                    </Button>
                    <Button variant="contained" disabled={!edit} onClick={cancelEdit} sx={{ ml: 1, bgcolor: '#ef4050' }}>
                        <CloseIcon />
                    </Button>
                </Box>
            </Grid>

            <Grid item xs={12}>
                {!edit && (
                    <Grid container sx={{ border: 0.1, borderRadius: 2, p: 1 }} mt={3}>
                        <Grid item xs={6}>
                            <FormLabel focused>Created By: </FormLabel>
                            <span>{nDefectDetails['createdBy']}</span>
                        </Grid>
                        <Grid item xs={6}>
                            <FormLabel focused>Created At: </FormLabel>
                            <span>{nDefectDetails['createdAt']}</span>
                        </Grid>
                        <Grid item xs={6}>
                            <FormLabel focused>Updated By: </FormLabel>
                            <span>{nDefectDetails['updatedBy']}</span>
                        </Grid>
                        <Grid item xs={6}>
                            <FormLabel focused>Updated At: </FormLabel>
                            <span>{nDefectDetails['updatedAt']}</span>
                        </Grid>
                    </Grid>
                )}
            </Grid>

            <Grid container spacing={2}>
                {!edit &&
                    props.defFields.map((field, index) => (
                        <>
                            {field['fieldType'] !== 'Editor' && (
                                <Grid item xs={field['fieldType'] === 'TextArea' ? 12 : 6} key={index}>
                                    <FormLabel>{field.displayName}</FormLabel>
                                    <TextField fullWidth size="small" disabled sx={{
                                        'bgcolor': '#f2f4f4', "& .MuiInputBase-input.Mui-disabled": {
                                            WebkitTextFillColor: "#616a6b",
                                        },
                                    }} value={
                                        field.isDefault
                                            ? nDefectDetails[field.fieldName] || '' // Default to '' if value is undefined
                                            : (nDefectDetails.dynamicFields && nDefectDetails.dynamicFields[field.fieldName]) || '' // Default to '' if value is undefined
                                    }></TextField>
                                </Grid>
                            )}
                            {field['fieldType'] === 'Editor' && (
                                <Grid item xs={12} key={index}>
                                    <FormLabel>{field.displayName}</FormLabel>
                                    <Box sx={{ border: 0.1, borderRadius: 2, p: 1 }}>
                                        <ReactQuill theme="bubble" readOnly value={field.isDefault ? nDefectDetails[field.fieldName] ? JSON.parse(nDefectDetails[field.fieldName]) : '' : nDefectDetails['dynamicFields'][field.fieldName] ? JSON.parse(nDefectDetails['dynamicFields'][field.fieldName]) : ''} />
                                    </Box>
                                </Grid>
                            )}
                        </>
                    ))
                }

                {edit &&
                    props.defFields.map((field, index) => (
                        <>
                            {field['fieldType'] === 'Text' && (
                                <Grid item xs={6} key={index}>
                                    <FormLabel>{field.displayName}</FormLabel>
                                    <TextField value={nDefectDetails[field.fieldName]} variant="outlined" size="small" fullWidth onChange={(e) => handleFieldChange(field.fieldName, e.target.value, field.isDefault)} />
                                </Grid>
                            )}
                            {field['fieldType'] === 'DropDown' && (
                                <Grid item xs={6} key={index}>
                                    <FormLabel>{field.displayName}</FormLabel>
                                    <Select
                                        fullWidth
                                        size="small"
                                        value={
                                            field.isDefault
                                                ? nDefectDetails[field.fieldName] || '' // Default to '' if value is undefined
                                                : (nDefectDetails.dynamicFields && nDefectDetails.dynamicFields[field.fieldName]) || '' // Default to '' if value is undefined
                                        }
                                        onChange={(e) => handleFieldChange(field.fieldName, e.target.value, field.isDefault)}
                                    >
                                        {field.defaultValue.map((dropDownValue, idx) => (
                                            <MenuItem value={dropDownValue} key={idx}>
                                                {dropDownValue}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Grid>
                            )}
                            {field['fieldType'] === 'UserDropDown' && (
                                <Grid item xs={6} key={index}>
                                    <FormLabel>{field.displayName}</FormLabel>
                                    <DynamicAutocomplete updateUser={setnUser} moduleId={5} defecnId={props.defectId} defectId={nDefectDetails['_id']} userName={nDefectDetails[field.fieldName]} />
                                </Grid>
                            )}
                            {field['fieldType'] === 'TextArea' && (
                                <Grid item xs={12} key={index}>
                                    <FormLabel>{field.displayName}</FormLabel>
                                    <TextField value={nDefectDetails[field.fieldName]} variant="outlined" size="small" fullWidth multiline rows={4} onChange={(e) => handleFieldChange(field.fieldName, e.target.value, field.isDefault)} />
                                </Grid>
                            )}
                            {field['fieldType'] === 'Editor' && (
                                <Grid item xs={12} key={index}>
                                    <FormLabel>{field.displayName}</FormLabel>
                                    <ReactQuill value={field.isDefault ? nDefectDetails[field.fieldName] ? JSON.parse(nDefectDetails[field.fieldName]) : '' : nDefectDetails['dynamicFields'][field.fieldName] ? JSON.parse(nDefectDetails['dynamicFields'][field.fieldName]) : ''} onChange={(content,delta,Sources,editor) => handleFieldChange(field.fieldName, decode(content,{level: "html5"}), field.isDefault,editor)} />
                                </Grid>
                            )}
                        </>
                    ))
                }
            </Grid>
            <Grid item xs={12}>
                {!props.defectId.includes('newDefect') && <Box mt={2} p={1}>
                    <AttachemntListComp attachmentList={attachmentList} handleItemDelete={handleDelete} file={file} fileName={fileName} handleFileChange={handleFileChange} handleUpload={handleUpload}></AttachemntListComp>
                </Box>}
            </Grid>
        </Grid>
    );
};
