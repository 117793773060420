import React, { useContext, useEffect, useState } from "react";


import ReactQuill from 'react-quill';



import Button from '@mui/material/Button';
import { Box, Divider, FormLabel, Grid, IconButton, Link, List, ListItem, ListItemText, Paper, Table, TextField, Typography, styled } from "@mui/material";

import PlayArrowIcon from '@mui/icons-material/PlayArrow';

import UploadFileIcon from '@mui/icons-material/UploadFile'

import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete'


import { tpTCContext } from "../../Context/testPlanTCContext";
import { decode } from 'html-entities';
import GmailTreeView1 from '../folderList/folderStructure'
import { getCreatedBy, getTestExecutionList, getTeTestCaseDetails, nDownloadFile, updateAttachment, updateTestSteps, uploadAttachment } from "../../api/apiCalls";



import { teTCContext } from "../../Context/teTCContext";
import { NodeModel } from "@minoru/react-dnd-treeview";
import { tcSelectedTCContext } from "../../Context/teSelectedTC";
import AddDeleteTableRows from "./AddDeleteTableRows";
import { TCStepsContext } from "../../Context/testSteps";
import './TestExecutionContent.css'
import { BASE_URL } from "../../api/axios";
import CustomizedSnackbars from "../snackBar/simpleSnackBar";
import { AttachemntListComp } from "../attachments/attachments";

export let TestExecutionContent = (props) => {
    //let {teTC,setTeTC} = useContext(teTCContext)
    let [teTCList, setTeTCList] = useState<NodeModel[]>([])
    let [selectedModule, setSelectedModule] = useState<number>(1)
    let [edit, setEdit] = useState<boolean>(false)
    let { tcSteps, setTcSteps } = useContext(TCStepsContext);
    let [teTCDetails, setTeTCDetails] = useState<any[]>([]);
    let [tempNode, setTempNode] = useState<any>();
    let [executionAssignedTo, setExecutionAssignedTo] = useState<any>();
    let [teSelectedTC, setTeSelectedTC] = useState<any>()
    const [teTestCase, setTeTestCases] = useState([]);
    let [attachmentList, setAttachmentList] = useState<any>([])
    let [tcnoRunCount, setnoRunCount] = useState<any>(0)
    let [tcpassCount, setPassCount] = useState<any>(0)
    let [tcfailCount, setFailCount] = useState<any>(0)
    let [statusList, setStatusList] = useState<any>()
    let [tcblockedCount, setBlockedCount] = useState<any>(0)
    let [tctotalCount, setTotalCount] = useState<any>(0)
    let [tcinProgressCount, setInProgressCount] = useState<any>(0)
    let [fields, setField] = useState(props.tcFields)
    let [teTC, setTeTC] = useState<any[]>();
    const { setTpTestCases } = useContext(tpTCContext)
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState('');
    const [errorState, setErrorState] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');
    const [severity, setSeverity] = React.useState('')

    const handleFileChange = (event) => {
        if(event.target.files[0])
        {
        setFile(event.target.files[0]);
        setFileName(event.target.files[0].name);
        }

    };
    const handleError = (errMessage: string, errorSeverity: string) => {
        setErrorState(true);
        setErrorMessage(errMessage);
        setSeverity(errorSeverity);
    };

    const setFalseMessage = async () => {
        setErrorState(false);
        setErrorMessage('');
    }
    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        color: theme.palette.text.secondary,
        height: '80vh'
    }));
    const handleUpload = async () => {
        try {
            if (file) {
                const formData = new FormData();
                formData.append('file', file);
                let pushData = {}

                let attachList = []
                if (attachmentList) {
                    attachList = [...attachmentList]
                }
                let returnResponse = await uploadAttachment(formData)
                if (returnResponse.status === 201) {
                    let nattachment = { 'attachmentId': returnResponse.data.fileID, 'name': file.name }
                    attachList.push(nattachment)
                    pushData['attachment'] = attachList
                    let updateTEAttach = await updateAttachment(teSelectedTC.id, props.testExeId, pushData)
                    if (updateTEAttach.status === 200) {
                        setAttachmentList(attachList)
                        setFileName('Upload Success')
                        await sleep(1000)
                        setFile(null)
                    }
                }
            }
        }
        catch (err) {
            console.error('Error fetching data:', err);
            setErrorState(true)
            setSeverity('error')
            setErrorMessage(err.response.data.error)
        }
    };
    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

    let handleSelect = async (node) => {
        if (node) {
            setTempNode(node)
            let tcDetailList = await getTeTestCaseDetails(node.id, props.testExeId)
            let tcDetails = tcDetailList[0]['TestCases']
            setTeTCDetails(tcDetailList)
            setTcSteps(tcDetails[0]['TestSteps'])
            setAttachmentList(tcDetails[0]['attachment'])

        }

    }

    let handleSave = async () => {
        try {
            let otcSteps = {}
            let tcStatus = 'No Run'
            otcSteps['TestSteps'] = tcSteps
            let stepCount = tcSteps.length
            let noRunCount = await checkStepStatus(tcSteps, "No Run")
            let passCount = await checkStepStatus(tcSteps, "Pass")
            let failCount = await checkStepStatus(tcSteps, "Fail")
            let blockedCount = await checkStepStatus(tcSteps, "Blocked")
            let inProgressCount = await checkStepStatus(tcSteps, "InProgress")


            if (failCount !== 0) {
                tcStatus = 'Fail'
                //setFailCount(tcfailCount+1)
                //setnoRunCount(tcnoRunCount-1)
            }

            if (blockedCount !== 0 && failCount === 0) {
                tcStatus = 'Blocked'
                //setBlockedCount(tcblockedCount+1)
                //setnoRunCount(tcnoRunCount-1)
            }
            if (noRunCount === stepCount) {
                tcStatus = 'No Run'
                //setnoRunCount(tcnoRunCount+1)
            }
            if (inProgressCount !== 0 && failCount === 0 && blockedCount === 0) {
                tcStatus = 'InProgress'
                //setInProgressCount(tcinProgressCount+1)
                //setnoRunCount(tcnoRunCount-1)
            }
            if (passCount !== 0 && failCount === 0 && blockedCount === 0) {
                tcStatus = 'InProgress'
                //setInProgressCount(tcinProgressCount+1)
                //setnoRunCount(tcnoRunCount-1)
            }
            if (passCount === stepCount) {
                tcStatus = 'Pass'
                //setPassCount(tcpassCount+1)
                //setnoRunCount(tcnoRunCount-1)
            }
            //updateObjectInArray(teTCDetails[0]['TestCases'][0]['TestCaseId'],"TestStatus",tcStatus)
            updateObjectInArray(teTCDetails[0]['TestCases'][0]['TestCaseId'], 'TestStatus', tcStatus)
            otcSteps['TestStatus'] = tcStatus

            let updateStep = await updateTestSteps(teSelectedTC.id, props.testExeId, otcSteps)
            await handleSelect(tempNode)
            setnoRunCount(await checktcStatus(statusList, 'No Run'))
            setPassCount(await checktcStatus(statusList, 'Pass'))
            setFailCount(await checktcStatus(statusList, 'Fail'))
            setBlockedCount(await checktcStatus(statusList, 'Blocked'))
            setInProgressCount(await checktcStatus(statusList, 'InProgress'))
            setEdit(false)
        }
        catch (err) {
            console.error('Error fetching data:', err);
            setErrorState(true)
            setSeverity('error')
            setErrorMessage(err.response.data.error)
        }
    }
    const updateObjectInArray = async (id, key, value) => {
        let istatusList = [...statusList]
        for (let ctc = 0; ctc < istatusList.length; ctc++) {
            if (istatusList[ctc]['TestCaseId'] == id) {
                istatusList[ctc]['TestStatus'] = value
                break
            }
        }
        setStatusList(istatusList)

    };

    let checkStepStatus = async (ntcSteps, checkStatus) => {
        return ntcSteps.filter(testStep => testStep.status === checkStatus).length
    }
    let checktcStatus = async (ntcs, checkStatus) => {
        if (ntcs) {
            return ntcs.filter(tc => tc.TestStatus === checkStatus).length
        }
    }

    let handleEdit = async () => {
        setEdit(true)
    }

    let getStatusCount = async () => {
        //alert(JSON.stringify(statusList))

    }

    let loadTc = async () => {
        try {
            let nteTestCases = await getTestExecutionList(`select=assignedTo,TestCases.TestCaseId,TestCases.TestStatus&TestExecutionId=${props.testExeId}`)
            let ntcs = nteTestCases[0]['TestCases']
            let assigned = await getCreatedBy(nteTestCases[0]['assignedTo'])
            setExecutionAssignedTo(assigned)
            setStatusList(ntcs)
            setnoRunCount(await checktcStatus(ntcs, 'No Run'))
            setPassCount(await checktcStatus(ntcs, 'Pass'))
            setFailCount(await checktcStatus(ntcs, 'Fail'))
            setBlockedCount(await checktcStatus(ntcs, 'Blocked'))
            setInProgressCount(await checktcStatus(ntcs, 'InProgress'))
            if (ntcs) {
                setTotalCount(ntcs.length)
            }
            let tcList = []
            if (nteTestCases.length !== 0) {
                setTeTC(nteTestCases[0]['TestCases'])
                for (let cTc = 0; cTc < nteTestCases[0]['TestCases'].length; cTc++) {
                    //alert(props.tcList[0]['TestCases'][cTc]['TestCaseId'])
                    let tcNode = {
                        id: nteTestCases[0]['TestCases'][cTc]['TestCaseId'],
                        parent: 0,
                        text: nteTestCases[0]['TestCases'][cTc]['TestCaseId'],
                        droppable: false,
                        data: {
                            "fileType": "testCase"
                        }
                    }
                    tcList.push(tcNode)
                }
                setTeTCList(tcList)

                //alert(JSON.stringify(tcList))


            }
        }
        catch (err) {
            setErrorState(true)
            setSeverity('error')
            setErrorMessage(err.response.data.error)
        }

    }
    let handleDelete = async (attachmentId) => {
        try {
            let nattachment = [...attachmentList]
            nattachment = attachmentList.filter(item => item.attachmentId !== attachmentId);
            let pushData = {}
            pushData['attachment'] = nattachment

            let updateTEAttach = await updateAttachment(teSelectedTC.id, props.testExeId, pushData)
            if (updateTEAttach.status === 200) {
                setAttachmentList(nattachment)
            }
        }
        catch (err) {
            setErrorState(true)
            setSeverity('error')
            setErrorMessage(err.response.data.error)
        }


    }
    let downloadFile = async (attachId) => {
        try {
            let downloadResp = await nDownloadFile(attachId)
        }
        catch (err) {
            setErrorState(true)
            setSeverity('error')
            setErrorMessage(err.response.data.error)
        }

    }



    useEffect(() => { loadTc() }, [])

    return (

        <tcSelectedTCContext.Provider value={{ teSelectedTC, setTeSelectedTC }}>
            <teTCContext.Provider value={{ teTC, setTeTC }} >
                <CustomizedSnackbars errorMessage={errorMessage} refresh={errorState} closeError={setFalseMessage} severity={severity} />
                <Grid container spacing={2} >
                    <Grid item xs={12} md={3}>
                        <Box sx={{ overflow: "auto", height: '80vh', pl: 1, pr: 1, pt: 2 }}>
                            <Item sx={{ 'height': '77vh', }}>

                                <b>Test Execution</b>
                                <GmailTreeView1 onSelect={handleSelect} testExeId={props.testExeId} teTCList={teTCList} ></GmailTreeView1>

                            </Item>
                        </Box>

                    </Grid>
                    <Grid item xs={12} md={9}>
                        <Grid container mt={2} sx={{ border: 0.1, borderRadius: 2, p: 1 }}>
                            <Grid item xs={4} >

                                <Typography variant="h6">Test Execution : {props.testExeId}</Typography>


                            </Grid>
                            <Grid display={'flex'} justifyContent="flex-end" item xs={7} ml={7} >
                                <Typography variant="h6">Assigned To : {executionAssignedTo}</Typography>
                            </Grid>

                        </Grid>
                        <Grid container alignContent={'center'} justifyContent={'center'} sx={{ border: 0.1, borderRadius: 2, p: 1, mt: 1 }}>
                            <Grid item xs={2}>
                                <Typography variant="subtitle2"><b>No Run</b></Typography>
                                <Typography variant="subtitle2"><b>{tcnoRunCount}</b></Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography variant="subtitle2" color={'orange'}><b>In Progress</b></Typography>
                                <Typography variant="subtitle2" color={'orange'}><b>{tcinProgressCount}</b></Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography variant="subtitle2" color={'green'}><b>Pass</b></Typography>
                                <Typography variant="subtitle2" color={'green'}><b>{tcpassCount}</b></Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography variant="subtitle2" color={'red'}><b>Fail</b></Typography>
                                <Typography variant="subtitle2" color={'red'}><b>{tcfailCount}</b></Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography variant="subtitle2" color={'maroon'}><b>Blocked</b></Typography>
                                <Typography variant="subtitle2" color={'maroon'}><b>{tcblockedCount}</b></Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography variant="subtitle2" color={'blue'}><b>Total</b></Typography>
                                <Typography variant="subtitle2" color={'blue'}><b>{tctotalCount}</b></Typography>
                            </Grid>
                        </Grid>

                        {teTCDetails[0] &&

                            <Box sx={{ height: '50vh' }}>
                                <Grid container mt={1}>
                                    <Grid item md={10} >
                                        <Typography variant="h6" color={'#1F618D'}>{teTCDetails[0]['TestCases'][0]['TestCaseId'] + ' : ' + teTCDetails[0]['TestCases'][0]['TestCaseSummary']}</Typography>
                                    </Grid>


                                    <Grid item md={2} justifyContent="flex-end">
                                        <Box display="flex" justifyContent="flex-end">
                                            <Button variant="contained" disabled={edit} onClick={handleEdit} sx={{ bgcolor: '#196db6' }}><PlayArrowIcon></PlayArrowIcon></Button>
                                            <Button variant="contained" disabled={!edit} onClick={handleSave} sx={{ ml: 1, bgcolor: '#1ABC9C' }}><SaveIcon></SaveIcon></Button>

                                        </Box>
                                    </Grid>

                                </Grid>
                                <Box sx={{ overflow: "auto", height: '55vh', border: 0.1, borderRadius: 2 }} mt={1}>

                                    <Grid container spacing={1} p={1}>
                                        <Typography variant="h6" ml={1}>Test Case Details</Typography>

                                        {props.tcFields.map((field, index) => (
                                            <>
                                                <Grid item xs={field.fieldType === 'TextArea' || field.fieldType === 'Editor' ? 12 : 6} key={index}>

                                                    {field.fieldName !== "requirementSummary" && field.fieldName !== "TestCaseSummary" && field.fieldName !== "Expected" && field.fieldName != "testStep" && (<FormLabel sx={{ asterisk: "red", color: "#1F618D" }} required={field.isMandatory} htmlFor={field.displayName}><b>{field.displayName}</b></FormLabel>)}
                                                    {field.fieldType === "Editor" && <ReactQuill theme="bubble" readOnly value={decode(teTCDetails[0]['TestCases'][0]['dynamicFields'][field.fieldName], { level: "html5" })} />}
                                                    {field.isDefault && field.fieldName !== "requirementSummary" && field.fieldName !== "TestCaseSummary" && field.fieldType !== "Editor" && field.fieldName !== "Expected" && field.fieldName !== "testStep" && <> <Box sx={{ background: '#f2f4f4', pl: 2, ml: field.fieldType === 'Editor' ? 5 : 0, typography: 'subtitle1', border: 0.1, borderColor: 'light grey', height: '5vh', alignContent: 'center' }} dangerouslySetInnerHTML={{ __html: teTCDetails[0]['TestCases'][0][field.fieldName] }}></Box>
                                                    </>}
                                                    {!field.isDefault && field.fieldName !== "requirementSummary" && field.fieldName !== "TestCaseSummary" && field.fieldType !== "Editor" && field.fieldName !== "Expected" && field.fieldName !== "testStep" && <> <Box sx={{ background: '#f2f4f4', pl: 2, ml: field.fieldType === 'Editor' ? 5 : 0, typography: 'subtitle1', border: 0.1, borderColor: 'light grey', height: '5vh', alignContent: 'center' }} dangerouslySetInnerHTML={{ __html: teTCDetails[0]['TestCases'][0]['dynamicFields'][field.fieldName] }}></Box>
                                                    </>}
                                                </Grid>


                                            </>
                                        ))}
                                        <Grid item xs={12} mt={4}>
                                            <Typography variant="h6">Steps</Typography>
                                            <Divider orientation="horizontal" flexItem />
                                            <AddDeleteTableRows rows={teTCDetails[0]['TestCases'][0]['TestSteps']} editable={edit} module={4}></AddDeleteTableRows>
                                        </Grid>

                                    </Grid>
                                    <Box mt={2} p={1}>
                                        <AttachemntListComp attachmentList={attachmentList} handleItemDelete={handleDelete} file={file} fileName={fileName} handleFileChange={handleFileChange} handleUpload={handleUpload} ></AttachemntListComp>
                                    </Box>
                                </Box>

                            </Box>
                        }
                    </Grid>
                </Grid>

            </teTCContext.Provider>
        </tcSelectedTCContext.Provider>

    )

}