// File: TableRow.tsx
import React from 'react';
import { TableRow as MuiTableRow, TableCell, Button, TextField, IconButton, Typography, Select, MenuItem } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
function TableRow({ row, index, handleChange, deleteTableRows, addTableRows, editable, module, stepDropDown }) {
    return (
        <MuiTableRow>
            {module == 1 &&
                <>
                    <TableCell >
                        {editable ? <TextField
                            fullWidth
                            multiline
                            size='small'
                            name="testStep"
                            value={row.testStep}
                            onChange={(e) => handleChange(index, 'testStep', e.target.value)}
                        /> : <TextField
                        fullWidth
                        multiline
                        size='small'
                        name="testStep"
                        value={row.testStep}
                        disabled
                        sx={{
                            'bgcolor': '#f2f4f4', "& .MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor: "#616a6b",
                            },
                        }}
                        
                    />}
                    </TableCell>

                    <TableCell>
                        {editable ? <TextField
                            fullWidth
                            multiline
                            size='small'
                            name="Expected"
                            value={row.Expected}
                            onChange={(e) => handleChange(index, 'Expected', e.target.value)}
                        /> : <TextField
                            fullWidth
                            multiline
                            disabled
                            size='small'
                            name="Expected"
                            value={row.Expected}
                            sx={{
                                'bgcolor': '#f2f4f4', "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "#616a6b",
                                },
                            }}
                        />}
                    </TableCell></>}
            {module == 4 &&
                <>
                    <TableCell size={'medium'}>
                        <Typography>{row.testStep}</Typography>
                    </TableCell>

                    <TableCell size={'medium'}>
                        <Typography>{row.Expected}</Typography>
                    </TableCell></>}
            {module == 4 && <TableCell align={!editable ? 'center' : 'right'} size={'small'}>
                {editable ?
                    <Select sx={{ width: '10vw' }} size="small" onChange={(e) => handleChange(index, 'status', e.target.value)} value={row.status}>
                        {stepDropDown.map((dropDownValue, index) => (
                            <MenuItem value={dropDownValue}>{dropDownValue}</MenuItem>
                        ))}
                    </Select> : <Typography>{row.status}</Typography>}
            </TableCell>}
            {editable && module == 1 && <TableCell size='small' align='right'>
                <IconButton onClick={() => addTableRows(index + 1)}><AddIcon color='primary'></AddIcon></IconButton>
                <IconButton onClick={() => deleteTableRows(index)}><DeleteIcon color={'error'}></DeleteIcon></IconButton>
            </TableCell>}


        </MuiTableRow>
    );
}

export default TableRow;
