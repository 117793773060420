import { Typography } from "@mui/material";
import MainLayout from "../Components/Layout/MainLayout";
import {  InputLabel, InputAdornment, IconButton, OutlinedInput, Stack, ThemeProvider, TextField, Button, FormControl,Box } from '@mui/material'
import { Link } from "react-router-dom";
const Dashboard = () => {
    return(
        <MainLayout>
            <Box marginTop={'30vh'}>
            <Stack   margin={'auto'} width={'40vw'} spacing={5} justifySelf={'center'} justifyContent={'center'} alignContent={'center'} alignSelf={'center'} >
                <Button variant="contained" href = "/requirements">Requirements</Button>
                <Button variant="contained" href="/testrepo">Test Repository</Button>
                <Button variant="contained" href="/projects">Test Automation</Button>
                <Button variant="contained" href="/projects">Test Execution</Button>

            </Stack>
            </Box>
        </MainLayout>
    );

}
export default Dashboard