import { Stack, Typography } from "@mui/material";
import MainLayout from "../Components/Layout/MainLayout";
import ItemsList from "../Components/Lists/mainList"
import { InputLabel, InputAdornment, IconButton, OutlinedInput, Grid, ThemeProvider, TextField, Button, FormControl, Box } from '@mui/material'

import AddIcon from '@mui/icons-material/Add';
import { Link } from "react-router-dom";
import useAxiosPrivate from "../Hooks/useAxiosPrivate";
import { useContext, useEffect, useState } from "react";
import { WSContext } from "../Context/workSpaceContext";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import GmailTreeView from '../Components/folderStructure/folderStructure'
import ScrollableTabs from "../Components/Tabs/ScrollableTabs";
import ArticleIcon from '@mui/icons-material/Article';
import Label from '@mui/icons-material/Label';
import { MyContext, MyContextData } from '../Context/requirementListContext';
import { tObject } from '../model/requirementsContext'
import { tabContext } from '../Context/TabContext'
import { ReqContext } from "../Context/requirementContext";
import { NodeModel } from "@minoru/react-dnd-treeview";
import { getFolderList, getTcList } from "../api/apiCalls";
import { selectedModuleContext } from "../Context/SelectedModuleContext";
import { fetchfoldtc } from "../common/fetchTestCase";
import { ListSkeleton } from "../Components/loader/loader";
import CustomizedSnackbars from "../Components/snackBar/simpleSnackBar";




const TestRepo = () => {
    let { selectedModule, setSelectedModule } = useContext(selectedModuleContext);
    let objs = useContext(tabContext);
    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        color: theme.palette.text.secondary,
        height: '90vh'
    }));
    const [objects, setObjects] = useState<tObject[]>([]);
    const [selectedNode, setSelectedNode] = useState<NodeModel>();
    const [loader, setLoader] = useState<boolean>(true);
    const [errorState, setErrorState] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [severity, setSeverity] = useState('')
    const handleError = (errMessage: string, errorSeverity: string) => {
        setErrorState(true);
        setErrorMessage(errMessage);
        setSeverity(errorSeverity);
    };

    const setFalseMessage = async () => {
        setErrorState(false);
        setErrorMessage('');
    }



    const contextValue: MyContextData = {
        objects,
        setObjects,
    };



    useEffect(() => {

        setSelectedModule(1)
        const fetchData = async () => {
            try {
                let data = await fetchfoldtc(0, []);
                setObjects(data);
               

            } catch (err) {
                console.error('Error fetching data:', err);
                setErrorState(true)
                setSeverity('error')
                setErrorMessage(err.response.data.error)
                console.error('Error fetching data:', err);
                console.error('Error fetching data:', err);
            }
            finally{
                setLoader(false);
            }
        };
        fetchData();
    }, []);




    return (

        <ReqContext.Provider value={{ selectedNode, setSelectedNode }}>
            <MyContext.Provider value={{ objects, setObjects }}>
                <MainLayout>
                <CustomizedSnackbars errorMessage={errorMessage} refresh={errorState} closeError={setFalseMessage} severity={severity} />
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={2} >
                            <Grid item xs={12} md={2}>
                                <Item >
                                    <b>Test Case</b>
                                    {!loader &&
                                        <GmailTreeView moduleId={1}></GmailTreeView>
                                    }
                                    {
                                        loader && <Stack spacing={2} mt={5}>

                                            <ListSkeleton listsToRender={10} />

                                        </Stack>

                                    }
                                </Item>

                            </Grid>
                            <Grid item xs={12} md={10}>
                                <Item>
                                    <ScrollableTabs moduleId={1}></ScrollableTabs>
                                </Item>
                            </Grid>
                        </Grid>
                    </Box>

                </MainLayout>
            </MyContext.Provider>
        </ReqContext.Provider>

    );

}
export default TestRepo