import { createContext } from "react";
import { PObject } from "../model/ProjectContext";

export type PContextProps = {
    pObject?: PObject;
    setpObject: React.Dispatch<React.SetStateAction<PObject>>;
  }

export const PContext = createContext<PContextProps>({ pObject: { projectId: '', projectName: '', projectUrl: '',projectDescription: '',createdBy:'',updatedBy:'' }, setpObject: () => {} });




