import React, { useState, useContext, useEffect } from "react";
import { DndProvider } from "react-dnd";
import { ThemeProvider, CssBaseline, Box, Button, Grid, Typography, TextField, IconButton, OutlinedInput, InputAdornment } from "@mui/material";
import {
  Tree,
  NodeModel,
  DragLayerMonitorProps,
  MultiBackend,
  getBackendOptions
} from "@minoru/react-dnd-treeview";
import { CustomData } from "./types";
import { CustomNode } from "./CustomNode";
import { CustomDragPreview } from "./CustomDragPreview";
import { theme } from "./theme";
import styles from "./App.module.css";
import { ReqContext } from "../../Context/requirementContext";
import { MyContext } from '../../Context/requirementListContext';
import { tabContext } from "../../Context/TabContext";
import { nTabProperties } from "../../Context/TabContext";
import { reqOpenFoldContext } from "../../Context/ReqOpenFoldContext";
import DescriptionIcon from "@mui/icons-material/Description";
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
import { createFolderApi, getFolderList } from "../../api/apiCalls";
import { ElectricalServices } from "@mui/icons-material";
import { selectedFolderContext } from "../../Context/SelectedFolderContext";
function App(props) {
  let { selectedFolder, setSelectedFolder } = useContext(selectedFolderContext)
  const { objects, setObjects } = useContext(MyContext);
  let selectedNodeObj = useContext(ReqContext)
  let selectedNode = selectedNodeObj.selectedNode
  const { setSelectedNode } = useContext(ReqContext);
  const ReqFoldObject = useContext(reqOpenFoldContext)
  let intialOpen = ReqFoldObject.reqOpenFoldObject
  //console.log('context', objects)
  const [treeData, setTreeData] = useState<NodeModel[]>(objects);
  let [nFoldName, setNFoldName] = useState<string>('')
  let [foldTextDisplay, setFoldTextDisplay] = useState<boolean>(false)
  const handleDrop = (newTree: NodeModel[]) => setTreeData(newTree);

  const handleSelect = (node: NodeModel) => {
    if (!node.droppable) {
      addTab(node);
    }
    else {
      setSelectedFolder(node.id)
    }

    setSelectedNode(node);
  }
  const { setTabObject } = useContext(tabContext)
  let tabs = useContext(tabContext).tabObject
  const addTab = (node) => {
    //alert(JSON.stringify(tabs))
    //alert(node.id)
    //alert(tabs.some(el => el.id === `${node.id}`))
    if (!tabs.some(el => el.id === `${node.id}`)) {
      let ntabs = [...tabs]
      for (let ntab of ntabs) {
        ntab['active'] = false
      }
      setTabObject([
        ...ntabs,
        {
          id: `${node.id}`,
          title: `${node.text}`,
          active: true,
          selectedModule: props.moduleId,
          favicon: `/images/${tabIcon(props.moduleId)}`
        },
      ]);
    }
    else {
      let ntabs = [...tabs]
      for (let ntab of ntabs) {
        if (ntab['id'] === node.id) {
          ntab['active'] = true
        }
        else {
          ntab['active'] = false
        }
      }
    }


  };
  let showFoldName = () => {
    setNFoldName('');
    setFoldTextDisplay(true)
  }
  let createFolder = async () => {
    let data = [...objects]
    let parent: number = 0;
    if (selectedNode) {
      if (selectedNode.droppable) {
        parent = typeof selectedNode.id === "number" ? selectedNode.id : parseInt(selectedNode.id as string, 10);
      }
      else {
        parent = typeof selectedNode.parent === "number" ? selectedNode.parent : parseInt(selectedNode.parent as string, 10);
      }
    }
    //to-do : should make it dynamic
    let createdBy = '643402068b556dee6a3e14de'
    let updatedBy = '643402068b556dee6a3e14de'
    let moduleId = props.moduleId
    let projectId = 1
    let parentFolder = parent
    let folderName = nFoldName
    let reqBody = { createdBy, updatedBy, moduleId, projectId, parentFolder, folderName }
    setFoldTextDisplay(false)
    let createFoldResp = await createFolderApi(reqBody)
    if (createFoldResp == 'Success') {
      let nReqFoldList = await getFolderList(parent, props.moduleId)

      for (let reqFold of nReqFoldList) {
        let finalFold = {
          "id": reqFold['id'],
          "parent": reqFold['parentFolder'],
          "droppable": true,
          "text": reqFold['folderName']
        }
        if (await objectExists(data, "id", reqFold['id']) == 0) {
          data.push(finalFold)
        }

      }
      setObjects(data)
    }
  }
  let createNewReq = () => {
    let data = [...objects]
    let parent: number = 0;
    if (selectedNode == undefined) {
      parent = 0
    }
    else {
      if (selectedNode.droppable) {
        parent = typeof selectedNode.id === "number" ? selectedNode.id : parseInt(selectedNode.id as string, 10);
      }
      else {
        parent = typeof selectedNode.parent === "number" ? selectedNode.parent : parseInt(selectedNode.parent as string, 10);
      }
    }
    let idStr = ''
    let textStr = ''
    
    let moduleId = props.moduleId
    if (moduleId == 2) {
      idStr = 'newReq'
      textStr = 'New Requirement'
      
    }
    if (moduleId == 1) {
      idStr = 'newTestCase'
      textStr = 'New Test Case'
      
    }
    if (moduleId == 3) {
      idStr = 'newTestPlan'
      textStr = 'New Test Plan'
    }
    if(moduleId == 5){
      idStr = 'newDefect'
      textStr = 'New Defect'
    }
    let finalFold = {
      "id": idStr + tabs.length + 1,
      "parent": parent,
      "droppable": false,
      "text": textStr,
      "favicon": `/images/${tabIcon(props.moduleId)}`
    }
    addTab(finalFold);
  }

  const objectExists = (array, key, value) => {
    return array.some(obj => obj[key] === value);
  };


  let cancelFoldName = () => {
    setFoldTextDisplay(false)
  }
  let handleChange = (event) => {
    setNFoldName(event.target.value)
  }
  let tabIcon = (tabType) =>{
    let iconName = ''
    switch (tabType) {
      
      case 2:
        return 'description.png';
      case 1:
        return 'plagiarism.png';  
      case 3:
        return 'work.png';
      case 4:
        return 'play.png'  
      case 5:
        return 'bug.png'  
      default:
        return null;
    }

    return iconName
  }


  return (
    <ThemeProvider theme={theme}>
      <div className={styles.app} >
        <div className={styles.current}>
          <Grid container>
            <>
              <Grid item xs={8}>
                <Typography variant={"subtitle1"} color={'#1F618D'}><b>

                  {selectedNode ? selectedNode.text : "Root"}
                </b>
                </Typography>

              </Grid>
              {!foldTextDisplay && (<Grid item xs={4}>
                <Box display="flex" justifyContent="flex-end">
                  <IconButton onClick={showFoldName} size={"small"} sx={{ "ml": 1, "bgcolor": "#196db6", color: '#fff', '&:hover': { 'color': 'grey' } }} >
                    <CreateNewFolderIcon></CreateNewFolderIcon>
                  </IconButton>
                  <IconButton onClick={createNewReq} sx={{ "ml": 1, "bgcolor": "#1ABC9C", color: '#fff', '&:hover': { 'color': 'grey' } }} size={"small"}>
                    <AddIcon></AddIcon>
                  </IconButton>
                </Box>
              </Grid>
              )}
            </>
            {foldTextDisplay && (<><Grid item mt={1} xs={12}>
              <OutlinedInput placeholder="New Folder Name" size="small" fullWidth value={nFoldName} onChange={handleChange} endAdornment={<InputAdornment position="end">
                <CheckIcon sx={{ cursor: "pointer" }} onClick={createFolder} fontSize="small" ></CheckIcon>
               
                  <ClearIcon sx={{ cursor: "pointer" }} fontSize="small" onClick={cancelFoldName}></ClearIcon>
                </InputAdornment>}></OutlinedInput>

            </Grid>
            </>)}


          </Grid>


        </div>

        <Box sx={{ 'overflow': 'auto' }}>
          <Tree
            tree={treeData}
            rootId={0}
            render={(
              node: NodeModel<CustomData>,
              { depth, isOpen, onToggle }
            ) => (
              <CustomNode
                moduleId={props.moduleId}
                node={node}
                depth={depth}
                isOpen={isOpen}
                isSelected={node.id === selectedNode?.id}
                onToggle={onToggle}
                onSelect={handleSelect}

              />
            )}
            dragPreviewRender={(
              monitorProps: DragLayerMonitorProps<CustomData>
            ) => <CustomDragPreview monitorProps={monitorProps} />}
            onDrop={handleDrop}
            classes={{
              draggingSource: styles.draggingSource,
              dropTarget: styles.dropTarget
            }}
            initialOpen={intialOpen}
          />
        </Box>
      </div>

    </ThemeProvider>
  );
}

export default App;
