import React from "react";
import FolderIcon from "@mui/icons-material/Folder";
import DescriptionIcon from "@mui/icons-material/Description";

type Props = {
  droppable: boolean;
};

export const TypeIcon: React.FC<Props> = (props) => {
  if (props.droppable) {
    return <FolderIcon />;
  }

  return <DescriptionIcon />;
};
