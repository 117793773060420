import { Box } from "@mui/material"
import { Outlet } from "react-router-dom"
import MainDrawer from "../NavBar/MainDrawer"
interface LayoutProps {
    children: React.ReactNode;
}
const MainLayout = ({ children }: LayoutProps) => {
    return (
        <main >
            <MainDrawer >
                <Box mt={'3vh'} height={'10vh'}>
                    {children}
                </Box>
            </MainDrawer>
        </main>
    )
}

export default MainLayout