import React, { useState, useEffect, useCallback } from 'react';
import { TextField, Autocomplete, CircularProgress } from '@mui/material';
import axios from 'axios';
import { getUsersList, updateDefect, updateTestExecution } from '../../api/apiCalls';
import debounce from 'lodash/debounce';

interface OptionType {
  label: string;
  value: string;
}

const DynamicAutocomplete = (props) => {
  const [options, setOptions] = useState<OptionType[]>([]);
  const [inputValue, setInputValue] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedValue, setSelectedValue] = useState<any>(null);

  // Debounce function to limit API calls
  const debouncedFetchUsers = useCallback(
    debounce(async (value) => {
      if (value) {
        try {
          setLoading(true);
          let nusersList = await getUsersList(value);
          let usersList = await mapUsersToOptions(nusersList);
          setOptions(usersList);
        } catch (error) {
          console.error('Error fetching users:', error);
        } finally {
          setLoading(false);
        }
      } else {  
        setOptions([]);
      }
    }, 300), // Debounce delay in milliseconds
    []
  );

  useEffect(() => {
    debouncedFetchUsers(inputValue);
  }, [inputValue, debouncedFetchUsers]);

  const handleSelection = async (event: React.ChangeEvent<{}>, newValue: string | null) => {
    if (props.moduleId === 3) {
      if (newValue) {
        try {
          setSelectedValue(newValue);
          const selectedOption = options.find(option => option.label === newValue);
          if (selectedOption) {
            let sendVal = { 'assignedTo': selectedOption.value };
            await updateTestExecution(sendVal, props.nteId);
          } else {
            console.warn('Selected option not found in the options list.');
          }
        } catch (error) {
          console.error('Error updating test execution:', error);
        }
      }
    }
    if (props.moduleId === 5) {
      setSelectedValue(newValue);
      const selectedOption = options.find(option => option.label === newValue);
      if (selectedOption) {
        let sendVal = { 'assignedTo': selectedOption.value };
        if (!props.defecnId.includes('newDefect')) {
          await updateDefect(props.defectId, sendVal);
        }
        props.updateUser(selectedOption);
      } else {
        console.warn('Selected option not found in the options list.');
      }
    }
  };
  

  const mapUsersToOptions = (users: any[]): OptionType[] => {
    return users.filter(user => !user.username.includes('service-account')).map((user) => ({
      label: user.username,
      value: user.id,
    }));
  };

  return (
    <Autocomplete
      freeSolo={false}
      sx={{ width: '100%', height: '70%' }}
      options={options.map((option) => option.label)}
      loading={loading}
      value={selectedValue}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      onChange={handleSelection}
      renderInput={(params) => (
        <TextField
          sx={{ width: '100%', height: '50%' }}
          {...params}
          size="small"
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default DynamicAutocomplete;
