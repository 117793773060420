import React, { useContext, useEffect, useState } from "react";
import {
  NodeModel,
  DropOptions,
  getDescendants
} from "@minoru/react-dnd-treeview";
import { TreeView } from "./TreeView";

import styles from "./App.module.css";
import { DndProvider } from "react-dnd";
import { ThemeProvider, CssBaseline, Grid, Button, Typography } from "@mui/material";
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import { MultiBackend, getBackendOptions } from "@minoru/react-dnd-treeview";
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { theme } from "./theme";
import { tpTCContext } from "../../Context/testPlanTCContext";
import { fetchfoldtc } from "../../common/fetchTestCase";
import { MyTCContext, MyTCContextData } from '../../Context/tcListContext';
import { tObject } from "../../model/requirementsContext";
import { selectedTCContext, selectedTCProps } from "../../Context/tesptPlanSelectedTC";
import { PropaneSharp } from "@mui/icons-material";
export default function App(props) {
  let tptestCases = useContext(tpTCContext).tptestCases
  let {setTpTestCases} = useContext(tpTCContext);
  let [tcTree, setTcTree] = useState([])
  //alert(JSON.stringify(tptestCases))
  const [treeData, setTreeData] = useState<NodeModel[]>([]);
  const [objects1, setObjects1] = useState<tObject[]>([]);
  const [selectedTCs, setSelectedTCs] = useState<any[]>([])
  const [testPlaTree, setTestPlanTree] = useState<any[]>([])
  const [selectedNodes, setSelectedNodes] = useState<NodeModel[]>([]);
  const contextValue: MyTCContextData = {
    objects1,
    setObjects1,
  };
  let selectedTCValue: selectedTCProps = {
    selectedTCs, setSelectedTCs
  }

  const handleDrop = (
    newTree: NodeModel[],
    { dragSourceId, dropTargetId }: DropOptions
  ) => {
    setTreeData(
      treeData.map((node) => {
        if (node.id === dragSourceId) {
          return {
            ...node,
            parent: dropTargetId
          };
        }

        return node;
      })
    );
  };
  const objectExists = (array, key, value) => {
    return array.some(obj => obj[key] === value);
  };
//`[{"TestCaseId":"TC-1","TestCaseSummary":"My First test case","TestStatus":"No Run","id":1},{"TestCaseId":"TC-2","TestCaseSummary":"My First test case","TestStatus":"No Run","id":2},{"TestCaseId":"TC-3","TestCaseSummary":"My First test case","TestStatus":"No Run","id":3}]`
//[{"id":"TC-4","Summary":"tt","parent":12,"droppable":false,"text":"TC-4","data":{"fileType":"testCase"}}]
  let addTestToPlan = async () => {
    //alert(JSON.stringify(selectedTCs))
    let testPlanTree = [...tptestCases]
    let tree1list = [...testPlaTree]
    //alert(JSON.stringify(selectedTCs))
    //alert(JSON.stringify(tptestCases))
    await selectedTCs.forEach(async (tc) => {
      const item = await tree1list.find((n) => n.id === tc.id);
      if (!item) {
        let tNode = { 'id': tc['id'], 'parent': 100, 'text': tc['text'] }
        tree1list.push(tNode)
        let tpNNode = {'TestCaseId':tc['text'],'TestCaseSummary':tc['Summary'],"TestStatus":"No Run","id":testPlanTree.length+1}
        testPlanTree.push(tpNNode)
        
      }


    })
  
    //setTpTestCases(testPlanTree)
    props.updateTPTCs(testPlanTree)
    setTestPlanTree(tree1list)
  }

  
  let tcList = []

  let tree1list = []
  useEffect(() => {

    // Simulating asynchronous data fetching
    const fetchData = async () => {

      tptestCases.forEach((tc) => {
        let tNode = { 'id': tc['TestCaseId'], 'parent': 100, 'text': tc['TestCaseId'] }
        tree1list.push(tNode)
      })
      setTestPlanTree(tree1list)

      if (tcList.length == 0) {
        tcList = await fetchfoldtc(0, []);
      }
      setTcTree(tcList)
      setObjects1(tcList);

    }
    fetchData();
  }, []);


  return (
    <selectedTCContext.Provider value={{ selectedTCs, setSelectedTCs }}>
      <MyTCContext.Provider value={{ objects1, setObjects1 }}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Grid container spacing={2} mt={1}>
            <Typography variant="h6" pl={5} mb={1}>Add Test Cases to Test Plan</Typography>
            <div style={{ 'height': '60vh' }} className={styles.rootGrid}>

              <div className={styles.leftColumn}>
                <Typography pl={5} variant={"subtitle1"}>Test Plan</Typography>
                <TreeView tree={testPlaTree} onDrop={handleDrop} rootId={100} />
              </div>
              <div className={styles.column}
              >
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  display="flex"
                  spacing={2}
                  pl={2}
                  sx={{ flexDirection: 'column', top: '40%', position: 'relative' }}
                >
                  <Button variant="contained" onClick={addTestToPlan} ><KeyboardDoubleArrowLeftIcon></KeyboardDoubleArrowLeftIcon></Button>
                  <Button variant="contained" sx={{ mt: 2 }}><KeyboardDoubleArrowRightIcon></KeyboardDoubleArrowRightIcon></Button>
                </Grid>
              </div>
              <div className={styles.rightColumn}>
                <Typography variant={"subtitle1"}>Test Repository</Typography>
                <TreeView tree={objects1} onDrop={handleDrop} rootId={0} />
              </div>
            </div>
          </Grid>

        </ThemeProvider>
      </MyTCContext.Provider>
    </selectedTCContext.Provider>
  );
}
