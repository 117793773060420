import React, { useContext, useEffect,useMemo } from "react";
import { useState } from "react";
import { Tabs } from "@sinm/react-chrome-tabs";
import { TabProperties } from "@sinm/react-chrome-tabs/dist/chrome-tabs";
import '@sinm/react-chrome-tabs/css/chrome-tabs.css';
// for dark mode
import '@sinm/react-chrome-tabs/css/chrome-tabs-dark-theme.css';
import { tabContext } from "../../Context/TabContext";
import { TabContent } from "./TabContent";
import './ScrollableTabs.css'
import { selectedModuleContext } from "../../Context/SelectedModuleContext";
let id = 1;
function BasicTabs({moduleId}) {  
    //let {selectedModule,setSelectedModule} = useContext(selectedModuleContext);
    /*const [tabs, setTabs] = useState<TabProperties[]>([
        { id: "abc", title: "test", active: true },
    ]);*/
    let selectedModule = moduleId;
    const {setTabObject} = useContext(tabContext)
    let tabs = useContext(tabContext).tabObject
 
    
    let activeTab = tabs.find((tab) => tab.active);
    const active = (id: string) => {
        setTabObject(tabs.map((tab) => ({ ...tab, active: id === tab.id })));
        activeTab = tabs.find((tab) => tab.active);
    };

    const close = async(id: string) => {
        
       let filter = await tabs.filter((tab) => tab.id !== id)
       console.log(filter)
       setTabObject(filter.map((tab) => ({ ...tab, active: tabs[tabs.length-2]['id'] === tab.id })));
       activeTab = tabs.find((tab) => tab.active);
      // await setTabObject(filter);
        //tabs[tabs.length-2].active=true
       // await active(tabs[tabs.length-2]['id'])
    };

    const reorder = (tabId: string, fromIndex: number, toIndex: number) => {
        const beforeTab = tabs.find(tab => tab.id === tabId);
        if (!beforeTab) {
            return;
        }
        let newTabs = tabs.filter(tab => tab.id !== tabId);
        newTabs.splice(toIndex, 0, beforeTab);
        setTabObject(newTabs);
    };
    const closeAll = () => setTabObject([]);
    
    return (
        <div>
            <Tabs
                darkMode={false}
                onTabClose={close}
                onTabReorder={reorder}
                onTabActive={active}
                tabs={tabs}
                
            ></Tabs>
            {activeTab && <div><TabContent closeTab = {close} tabid = {activeTab.id} moduleId={activeTab.selectedModule}></TabContent></div>}

            
            
        </div>
    );

}
const MemoizedMyComponent = React.memo(BasicTabs);

export default MemoizedMyComponent;
