import React, { useEffect, useRef, useState } from 'react';
import { ParameterTypeRegistry } from '@cucumber/cucumber-expressions';
import {
  buildSuggestions,
  ExpressionBuilder,
  jsSearchIndex,
  LanguageName,
  Source,
} from '@cucumber/language-service';
import { WasmParserAdapter } from '@cucumber/language-service/wasm';
import SaveIcon from '@mui/icons-material/Save';
import * as monaco from 'monaco-editor/esm/vs/editor/editor.api';
import { Box, Button, Grid, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ConfigureEditor, configureMonaco } from './src/index';
import { getAutomationStepDef, getSavedFeature, saveFeature } from '../../api/apiCalls';
import ObjectRepo from '../objectRepo/objectRepo';

const GherkinEditor = (props) => {
  const javascriptEditorRef = useRef<HTMLDivElement | null>(null);
  const editorInstanceRef = useRef<monaco.editor.IStandaloneCodeEditor | null>(null);
  const [value, setValue] = useState<string>('');
  const [currentLineNumber, setCurrentLineNumber] = useState<number>(1);
  const [currentColumnNumber, setCurrentColumnNumber] = useState<number>(1);
  const [insertTemp, setInsertTemp] = useState<string>('test');

  const makeConfigureEditor = async (javaFiles) => {
    monaco.languages.register({ id: 'gherkin' });

    const adapter = new WasmParserAdapter('.');
    await adapter.init();
    const expressionBuilder = new ExpressionBuilder(adapter);

    const sources = javaFiles.map((file) => ({
      languageName: 'tsx' as LanguageName,
      uri: file.uri,
      content: file.content,
    }));

    const { expressionLinks, errors } = expressionBuilder.build(sources, []);
    for (const error of errors) {
      console.error(error);
    }
    const expressions = expressionLinks.map((link) => link.expression);

    const registry = new ParameterTypeRegistry();
    const docs = buildSuggestions(registry, [], expressions);
    const index = jsSearchIndex(docs);
    return configureMonaco(monaco, index, expressions);
  };

  const mainFun = async (nOption) => {
    const javaFiles = await getAutomationStepDef();

    makeConfigureEditor(javaFiles)
      .then((configureEditor) => {
        if (javascriptEditorRef.current) {
          const editor = monaco.editor.create(javascriptEditorRef.current, nOption);
          editorInstanceRef.current = editor;
          editor.onDidChangeModelContent(() => {
            setValue(editor.getValue());
          });

          // Configure the editor
          configureEditor(editor);

          // Set up mouse move event listener to get current line and column
          editor.onMouseMove((e) => {
            if (e.target.position) {
              setCurrentLineNumber(e.target.position.lineNumber);
              setCurrentColumnNumber(e.target.position.column);
            }
          });
        }
      })
      .catch((err) => console.error(err.stack));
  };

  const tesedits = () => {
    if (editorInstanceRef.current) {
      editorInstanceRef.current.executeEdits("", [
        {
          range: new monaco.Range(
            currentLineNumber,
            currentColumnNumber,
            currentLineNumber,
            currentColumnNumber
          ),
          text: insertTemp,
          forceMoveMarkers: true,
        },
      ]);
    }
  };

  const getAutomationScript = async () => {
    let savedFeature = await getSavedFeature(props.testCaseId);

    if (savedFeature.length === 0) {
      savedFeature = `
        @${props.testCaseId}
        Feature: ${props.testCaseId}
          Scenario: ${props.testCaseId}
      `;
    } else {
      savedFeature = savedFeature[0]['automationScript'];
    }

    const nOption = {
      value: savedFeature,
      language: 'gherkin',
      theme: 'vs-dark',
      'semanticHighlighting.enabled': true,
    };

    await mainFun(nOption);
  };

  useEffect(() => {
    getAutomationScript();
  }, []);

  const handleSave = async () => {
    const currentValue = editorInstanceRef.current?.getValue();

    let sendData = {
      TestCaseId: props.testCaseId,
      automationScript: currentValue,
    };

    await saveFeature(props.testCaseId, sendData);
  };

  const moveToEditor = (e) => {
    var dataTransfer = e.dataTransfer;
    dataTransfer.setData("Text", "\n\t" + insertTemp + "\n");
  };

  return (
    <div onDragOver={(e) => e.preventDefault()} onDragStart={moveToEditor} onDragEnd={tesedits}>
      <Box>
        <Grid container>
          <Grid item p={1} xs={4}>
            <Typography variant="h6">{props.testCaseId}</Typography>
          </Grid>
          <Grid item xs={8} p={1} display={'flex'} justifyContent="flex-end">
            <Button variant="contained" onClick={handleSave} sx={{ ml: 1, bgcolor: '#1ABC9C' }}>
              <SaveIcon />
            </Button>
            <IconButton onClick={props.onButtonClick}>
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid item xs={8}>
            <div style={{ display: 'flex', gap: '20px' }}>
              <div id="editor1" ref={javascriptEditorRef} style={{ width: '100%', height: '75vh' }}></div>
            </div>
          </Grid>
          <Grid item xs={4}>
            <Typography variant='subtitle1'>Object Repository</Typography>
            <ObjectRepo />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default GherkinEditor;
