import { StayPrimaryLandscape } from "@mui/icons-material";
import { createTheme  } from "@mui/material";


const theme = createTheme({
    palette:{
        primary: {
            light: '#244855',
            main: '#244855',
            dark: '#003352',
            contrastText: '#fff',
          },
          secondary: {
            light: '#FFFFFF',
            main: '#B4CAD6',
            contrastText: '#fff',
            dark: '#4D616C'
          },
          success: {
            main: '#338A3E',
          },
          error: {
            main: '#BA000D'
          }
          
    },
    typography: {
        fontFamily: [
          '"Helvetica Neue"',
        ].join(','),
      },
      components: {
        MuiDrawer: {
          styleOverrides: {
            paper: {
              backgroundColor: '#244855',
              color: "#fff",
              borderBottomRightRadius: '16px'
            }
          }
        },
        MuiListItemButton: {
          styleOverrides: {
            root: {
              backgroundColor: '#244855',
              color: '#fff',
              border: '1',
    
              '&.Mui-selected': {
                backgroundColor: '#fff',
                color: '#006685',
              },
              
            },
          },
        },
        MuiFormLabel:{
          styleOverrides:{
            asterisk:{
              color: '#db3131',
            }
          }
        }
        
      }
})

export default theme