import React, { useContext } from "react";
import Typography from "@mui/material/Typography";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { TypeIcon } from "./TypeIcon";
import { NodeModel } from "@minoru/react-dnd-treeview";
import styles from "./CustomNode.module.css";
import { Checkbox } from "@mui/material";
import { fetchfoldtc } from "../../common/fetchTestCase";
import { MyTCContext } from "../../Context/tcListContext";

type Props = {
  node: NodeModel;
  depth: number;
  isOpen: boolean;
  isSelected?: boolean;
 
  onToggle: (id: NodeModel["id"]) => void;
  onSelect?: (node: NodeModel) => void; 
};

export const CustomNode: React.FC<Props> = (props) => {
  let { objects1, setObjects1 } = useContext(MyTCContext)
  let tree = props
  const { id, droppable } = props.node;
  const indent = props.depth * 24;
 
  const handleSelect = () => {
    props.onSelect(props.node)};
     const handleToggle = async(e: React.MouseEvent) => {
    let data = [...objects1]
    
    e.stopPropagation();
    props.onToggle(props.node.id);
    if (!props.isOpen) {
     
      let nodeData =await fetchfoldtc(props.node.id,objects1)
      
      setObjects1(nodeData);
     
    }
  };
  
  return (
    <div
    className={`tree-node ${styles.root}`}
    style={{ paddingInlineStart: indent }}
    
  >
    <div
      className={`${styles.expandIconWrapper} ${props.isOpen ? styles.isOpen : ""
        }`}
    >
      {props.node.droppable && (
        <div onClick={handleToggle}>
          <ArrowRightIcon />
        </div>
      )}
    </div>
    {!props.node.droppable && (<div>
        <Checkbox
          color="primary"
          size="small"
          checked={props.isSelected}
          onClick={handleSelect}
        />
      </div>)}
    
    <div>
      <TypeIcon droppable={droppable}  />
    </div>

    <div className={styles.labelGridItem}>
      <Typography variant="body2">{props.node.text}</Typography>
    </div>
  </div>
  );
};
