import { Box, Button, Divider, Grid, IconButton, Link, List, ListItem, ListItemText, Typography } from "@mui/material"
import DeleteIcon from '@mui/icons-material/Delete'
import { BASE_URL } from "../../api/axios";
import UploadFileIcon from '@mui/icons-material/UploadFile'
export let AttachemntListComp = (props) => {
    let handleDelete = (itemId) => {
        props.handleItemDelete(itemId);
    }

    return (
        <>
            <Grid container>
                <Grid item xs={2}>
                    <Typography variant="h6">Attachments</Typography>
                </Grid>
                <Grid item xs={10} justifyContent="flex-end">
                    <Box display="flex" justifyContent="flex-end">
                        {props.file && <Typography variant="body1" p={1}> {props.fileName}</Typography>}
                        <input
                            accept="*"
                            style={{ display: 'none' }}
                            id="file-input"
                            type="file"
                            onChange={(e) => props.handleFileChange(e)}
                        />
                        <label htmlFor="file-input">
                            <Button
                                variant="contained"
                                color="primary"
                                component="span"
                                startIcon={<UploadFileIcon />}
                            >
                                Choose File
                            </Button>
                        </label>

                        {props.file && <Button
                            variant="contained"
                            color="success"
                            onClick={props.handleUpload}
                            style={{ marginLeft: '10px' }}
                        >
                            Upload File
                        </Button>}

                    </Box>
                </Grid>
                <Grid item xs={12} mt={2}>
                    {props.attachmentList && <List>
                        {props.attachmentList.map((item) => (
                            <>
                                <ListItem
                                    key={item.attachmentId}
                                    secondaryAction={
                                        <IconButton edge="end" aria-label="delete" onClick={() => handleDelete(item.attachmentId)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    }
                                >
                                    <ListItemText primary={<Link href={`${BASE_URL}/api/v1/attachment/download/file/${item.attachmentId}`} >{item.name}</Link>} />
                                </ListItem>
                                <Divider orientation="horizontal" flexItem />
                            </>
                        ))}


                    </List>}</Grid>
            </Grid></>)

}