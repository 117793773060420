import { type } from 'os';
import React, { useEffect, createContext, useContext, useState, ReactNode, Dispatch, SetStateAction } from 'react';

export type tCase = {
    TestCaseId:string,
    TestCaseSummary:string,
    TestStatus:string,
}

export type testPlanProps ={
    tptestCases?:tCase[]
    setTpTestCases: React.Dispatch<React.SetStateAction<tCase[]>>;
}
export const tpTCContext = createContext<testPlanProps|undefined>(undefined);
