// File: AddDeleteTableRows.tsx
import React, { useContext, useEffect, useState } from 'react';
import TableRow from './TableRows';
import { Button, Table, TableBody, TableCell, TableHead, TableRow as MuiTableRow } from '@mui/material';
import { TCStepsContext } from "../../Context/testSteps";
import { getFields } from '../../api/apiCalls';
function AddDeleteTableRows(props) {
    let {tcSteps,setTcSteps} = useContext(TCStepsContext);
    const [rowsData, setRowsData] = useState(props.rows);
    let [stepDropDown,setStepDropDown] = useState<any>();
    useEffect(() => {
        getExeFields()  
        setRowsData([...props.rows]);
       
    }, [props.rows]);
    
    const addTableRows = (index) => {
        const newRow = { testStep: '', Expected: ''};
        const updatedRows = [...rowsData];
        updatedRows.splice(index, 0, newRow);
        setRowsData(updatedRows);
        setTcSteps(updatedRows)
    };

    const deleteTableRows = (index) => {
        const updatedRows = [...rowsData];
        updatedRows.splice(index, 1);
        setRowsData(updatedRows);
        setTcSteps(updatedRows)
    };

    const handleChange = (index, field, value) => {
        const updatedRows = [...rowsData];
        updatedRows[index][field] = value;
        setRowsData(updatedRows);
        setTcSteps(updatedRows)
    };

    let getExeFields = async () => {
        if(props.module == 4)
        {
            let reqFields = await getFields(4)
            setStepDropDown(reqFields[0]['defaultValue'])
        }
       
    }

    return (
        <div>
            <Table>
                <TableBody>
                    <MuiTableRow>
                        <TableCell align={!props.editable?'center':'left'}  variant='head' >Steps</TableCell>
                        <TableCell align={!props.editable?'center':'left'} variant='head'>Expected</TableCell>
                        {props.editable && props.module == 1 && <TableCell  align= {'right'} size='small' ></TableCell>}
                        {props.module == 4 && <TableCell variant='head' align={'center'}>Status</TableCell>}
                    </MuiTableRow>
                </TableBody>
                <TableBody>
                    {rowsData.map((row, index) => (
                        <TableRow
                            module={props.module}
                            key={index}
                            row={row}
                            index={index}
                            handleChange={handleChange}
                            deleteTableRows={deleteTableRows}
                            addTableRows={addTableRows}
                            editable={props.editable}
                            stepDropDown = {stepDropDown}
                        />
                    ))}
                </TableBody>
            </Table>
        </div>
    );
}

export default AddDeleteTableRows;


