import * as React from 'react';
import { styled, useTheme, Theme, CSSObject, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import theme1 from '../../Theme/theme';
import SchoolIcon from '@mui/icons-material/School';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import PlagiarismIcon from '@mui/icons-material/Plagiarism';
import HomeIcon from '@mui/icons-material/Home';
import ComputerIcon from '@mui/icons-material/Computer';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import CopyAllOutlinedIcon from '@mui/icons-material/CopyAllOutlined';
import DescriptionIcon from "@mui/icons-material/Description";
import { Stack } from '@mui/system';
import CustomizedMenus from '../Menu/userMenu';
import { Link, useLocation } from 'react-router-dom';
import Button from '@mui/material/Button';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import WorkIcon from '@mui/icons-material/Work';
import BugReportIcon from '@mui/icons-material/BugReport';




const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(5)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(5)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  backgroundColor: '#fff',
  color: theme1.palette.primary.dark,


  zIndex: theme.zIndex.drawer + 10,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,

    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);
interface LayoutProps {
  children: React.ReactNode;
}

export default function MainDrawer({ children }: LayoutProps) {
  const { pathname } = useLocation();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [userName, setUserName] = React.useState(localStorage.getItem('user'))

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <ThemeProvider theme={theme1}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar sx={{ 'borderBottomLeftRadius': '16px', 'textAlign': 'center', 'top': 'auto', 'bgcolor': 'primary.main', 'height': '5vh' }} elevation={0} position="fixed" open={open}>
          <Toolbar >
            <Box mb={2}>
            <img src='/images/logo-dashboard1.png' height={'25vh'} width={'25vw'}></img>
            </Box>
            <Stack direction={'row'}  sx={{ marginLeft: "auto" }} mb={3} >
              <Button id="demo-customized-button"
                aria-controls={open ? 'demo-customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                sx={{ 'color': '#fff' }}
                disableElevation>
                <SearchOutlinedIcon fontSize='small' sx={{ 'color': '#fff' }}></SearchOutlinedIcon></Button>
                <Button id="demo-customized-button"
                aria-controls={open ? 'demo-customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                sx={{ 'color': '#fff' }}
                disableElevation>
              <NotificationsNoneIcon fontSize='small' sx={{ 'color': '#fff' }}></NotificationsNoneIcon></Button>

              <Box >
                <CustomizedMenus></CustomizedMenus>
              </Box>


            </Stack>

          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open} >
          <DrawerHeader />
          <Divider />
          <List >
            <Link to={'/dashboard'} style={{ textDecoration: 'none' }}>
              <ListItem  style={{ 'textDecoration': 'none' }} key={'Home'} disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                  selected={'/dashboard' === pathname}
                  style={{ textDecoration: 'none' }}
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                    '&.Mui-selected': {
                      
                      backgroundColor: '#F2545B', // Change to your desired color
                      '&:hover': {
                        backgroundColor: '#F2545B', // Hover effect
                      },
                    },
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                      color: '#fff'
                    }}
                  >
                    <HomeIcon />
                  </ListItemIcon>
                  <ListItemText style={{ textDecoration: 'none' }} primary={'Home'} sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
            </Link>
            <Link to={'/requirements'} style={{ textDecoration: 'none' }}>
              <ListItem  key={'Requirements'} disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                 selected={'/requirements' === pathname}
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                    '&.Mui-selected': {
                     
                      backgroundColor: '#F2545B', // Change to your desired color
                      '&:hover': {
                        backgroundColor: '#F2545B', // Hover effect
                      },
                    },
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                      color: '#fff'
                    }}
                  >
                    < DescriptionIcon />
                  </ListItemIcon>
                  <ListItemText primary={'Requirements'} sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
            </Link>
            <Link to={'/testrepo'} style={{ textDecoration: 'none' }}>
              <ListItem key={'Test Repository'} disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                 selected={'/testrepo' === pathname}
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                    '&.Mui-selected': {
                    
                      backgroundColor: '#F2545B', // Change to your desired color
                      '&:hover': {
                        backgroundColor: '#F2545B', // Hover effect
                      },
                    },
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                      color: '#fff'
                    }}
                  >
                    <PlagiarismIcon />
                  </ListItemIcon>
                  <ListItemText primary={'Test Repository'} sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
            </Link>
            <Link to={'/testplan'} style={{ textDecoration: 'none' }}>
              <ListItem key={'Test Plan'} disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                 selected={'/testplan' === pathname}
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                    '&.Mui-selected': {
                     
                      backgroundColor: '#F2545B', // Change to your desired color
                      '&:hover': {
                        backgroundColor: '#F2545B', // Hover effect
                      },
                    },
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                      color: '#fff'
                    }}
                  >
                    <WorkIcon />
                  </ListItemIcon>
                  <ListItemText primary={'Test Plan'} sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
            </Link>
            <Link to={'/testexecution'} style={{ textDecoration: 'none' }}>
              <ListItem key={'Test Execution'} disablePadding sx={{ display: 'block'}}>
                <ListItemButton
                 selected={'/testexecution' === pathname}
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                    '&.Mui-selected': {
                      color:'black',
                      backgroundColor: '#F2545B', // Change to your desired color
                      '&:hover': {
                        backgroundColor: '#F2545B', // Hover effect
                      },
                    },
                   
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                      color: '#fff'
                    }}
                  >
                    <PlayArrowIcon />
                  </ListItemIcon>
                  <ListItemText primary={'Test Execution'} sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
            </Link>
            <Link to={'/defects'} style={{ textDecoration: 'none' }}>
              <ListItem key={'Defect'} disablePadding sx={{ display: 'block'}}>
                <ListItemButton
                 selected={'/defects' === pathname}
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                    '&.Mui-selected': {
                      color:'black',
                      backgroundColor: '#F2545B', // Change to your desired color
                      '&:hover': {
                        backgroundColor: '#F2545B', // Hover effect
                      },
                    },
                   
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                      color: '#fff'
                    }}
                  >
                   <BugReportIcon></BugReportIcon>
                  </ListItemIcon>
                  <ListItemText primary={'Defect'} sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
            </Link>

          </List>
          <Divider />

          <DrawerHeader>
            <IconButton onClick={handleDrawerClose} sx={{ 'color': '#fff', display: !open ? 'none' : 'flex' }}>
              {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
            <IconButton onClick={handleDrawerOpen} sx={{ 'color': '#fff', display: open ? 'none' : 'flex' }}>
              {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </DrawerHeader>
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          {children}

        </Box>
      </Box>
    </ThemeProvider>
  );
}

