import React, { useEffect, createContext, useContext, useState, ReactNode, Dispatch, SetStateAction } from 'react';
import { TabProperties } from "@sinm/react-chrome-tabs/dist/chrome-tabs";

export type nTabProperties = TabProperties & {
  selectedModule: number
}

export type tabContextProps = {
    tabObject?: nTabProperties[];
    setTabObject: React.Dispatch<React.SetStateAction<nTabProperties[]>>;
  }
export const tabContext = createContext<tabContextProps|undefined>(undefined);
