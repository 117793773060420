import React, { useContext, useState } from "react";
import { Tree, TreeProps, NodeModel } from "@minoru/react-dnd-treeview";
import { CustomNode } from "./CustomNode";
import { CustomDragPreview } from "./CustomDragPreview";
import styles from "./TreeView.module.css";
import { selectedTCContext,selectedTCProps } from "../../Context/tesptPlanSelectedTC";
type Props<T> = Pick<TreeProps<T>, "tree" | "onDrop" | "rootId" >;



export const TreeView: React.FC<Props<any>> = (props) => {
  const [selectedNodes, setSelectedNodes] = useState<NodeModel[]>([]);
  const {selectedTCs, setSelectedTCs} = useContext(selectedTCContext);
  const handleSelect = async(node: NodeModel) => {
    const item = await selectedNodes.find((n) => n.id === node.id);

    if (!item) {
     await setSelectedNodes([...selectedNodes, node]);
     setSelectedTCs([...selectedNodes, node])
    } else {
      await setSelectedNodes(await selectedNodes.filter((n) => n.id !== node.id));
      setSelectedTCs(selectedNodes.filter((n) => n.id !== node.id))
    }
   
  };
 

  return (
    <Tree
      tree={props.tree}
      onDrop={props.onDrop}
      rootId={props.rootId}
      classes={{
        root: styles.treeRoot,
        draggingSource: styles.draggingSource,
        dropTarget: styles.dropTarget
      }}
      render={(node, options) => <CustomNode isSelected={!!selectedNodes.find((n) => n.id === node.id)} onSelect={handleSelect} node={node} {...options} />}
      dragPreviewRender={(monitorProps) => (
        <CustomDragPreview monitorProps={monitorProps} />
      )}
    />)
};
