import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { WSContext, WSContextProps } from "./Context/workSpaceContext";
import { WSObject } from "./model/workSpaceContext";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './Context/AuthProvider';
import keycloak from './keycloak';


keycloak.init({ onLoad: 'login-required' }).then(authenticated => {
  if (authenticated) {
    
    const root = ReactDOM.createRoot(
      document.getElementById('root') as HTMLElement
    );
    root.render(
      
        <App />
      
    );
  } else {
    console.warn('User is not authenticated');
  }
}).catch(error => {
  console.error('Keycloak initialization failed:', error);
});

