
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Layout from "./Components/Layout/Layout";
import RequireAuth from './Components/requireAuth/requireAuth';
import PublicOption from './Components/requireAuth/publicOption';
import Login from './Pages/Login'
import Page404 from './Pages/Page404';
import Dashboard from './Pages/dashboard';
import SignUpPage from './Pages/signUp';
import Requirements from './Pages/requirements';
import TestPlan from './Pages/testPlan';
import TestRepo from './Pages/testcases';
import Projects from './Pages/projects';
import Profile from './Pages/profile';
import Templates from './Pages/templates';
import CreateNewProject from './Pages/createNewProject';
import { useEffect, useState } from "react";
import { WSContext } from "./Context/workSpaceContext";
import { WSObject } from "./model/workSpaceContext";
import { PContext } from './Context/ProjectContext';
import { PObject } from './model/ProjectContext';
import { ReqContext } from "./Context/requirementContext";
import { MyContext, MyContextData } from './Context/requirementListContext';
import { TemplateContext } from './Context/TemplateContext';
import { TemplateObject } from './model/TemplateModel'
import React from 'react';
import AuthProvider from './Context/AuthProvider';
import { tabContext } from './Context/TabContext';
import { TabProperties } from "@sinm/react-chrome-tabs/dist/chrome-tabs";
import { nTabProperties } from './Context/TabContext';
import { reqOpenFoldContext } from "./Context/ReqOpenFoldContext";
import { selectedModuleContext } from './Context/SelectedModuleContext';
import { tpTCContext } from './Context/testPlanTCContext';
import { NodeModel } from "@minoru/react-dnd-treeview";
import { tObject } from './model/requirementsContext'
import { tCase } from './Context/testPlanTCContext';
import { DndProvider } from "react-dnd";
import { ThemeProvider, CssBaseline } from "@mui/material";
import TestExecution from './Pages/testExecution';
import { selectedFolderContext } from "./Context/SelectedFolderContext";
import { MultiBackend, getBackendOptions } from "@minoru/react-dnd-treeview";
import { MyTCContext } from './Context/tcListContext';
import Defects from './Pages/defects'
function App() {
  let tabLocalStorage: nTabProperties[] = []
  if (localStorage.getItem('tabs') != undefined) {
    tabLocalStorage = JSON.parse(localStorage.getItem('tabs'));
  }
  const [selectedFolder, setSelectedFolder] = useState<number>(0);
  const [wsObject, setWsObject] = useState<WSObject>({ workspaceName: '', workspaceId: '', imageName: '' });
  const [templateObject, setTemplateObject] = useState<TemplateObject>({ TemplateName: '', templateId: '', gitURL: '' });
  const [pObject, setpObject] = useState<PObject>({ projectName: '', projectDescription: '', projectUrl: '', projectId: '', createdBy: '', updatedBy: '' });
  const [tabObject, setTabObject] = useState<nTabProperties[]>(tabLocalStorage || [])
  const [reqOpenFoldObject, setReqFoldObject] = useState<(string | number)[] | undefined>();
  const [selectedModule, setSelectedModule] = useState<number | undefined>();
  const [selectedNode, setSelectedNode] = useState<NodeModel>();
  const [objects, setObjects] = useState<tObject[]>([]);
  const [tptestCases, setTpTestCases] = useState<tCase[]>([])

  useEffect(() => {
    setReqFoldObject([])
  }, [])

  useEffect(() => {
    localStorage.setItem('tabs', JSON.stringify(tabObject));
  }, [tabObject]);


  return (
    <selectedFolderContext.Provider value={{ setSelectedFolder, selectedFolder }}>
      <DndProvider backend={MultiBackend} options={getBackendOptions()}>
        <selectedModuleContext.Provider value={{ selectedModule, setSelectedModule }}>
          <reqOpenFoldContext.Provider value={{ reqOpenFoldObject, setReqFoldObject }}>
            <tabContext.Provider value={{ tabObject, setTabObject }}>
              <WSContext.Provider value={{ wsObject, setWsObject }}  >
                <TemplateContext.Provider value={{ templateObject, setTemplateObject }}>
                  <PContext.Provider value={{ pObject, setpObject }}>
                    <BrowserRouter>
                      <Routes>
                        <Route path="/" element={<Dashboard />}/>
                          <Route path="*" element={<Page404 />} />
                          <Route path='/dashboard' element={<Dashboard />} />
                          <Route path='/profile' element={<Profile />} />
                          <Route path='/requirements' element={<Requirements />} />
                          <Route path='/testrepo' element={<TestRepo />} />
                          <Route path='/testplan' element={<TestPlan />} />
                          <Route path='/testexecution' element={<TestExecution />} />
                          <Route path='/defects' element={<Defects />} />
                        
                      </Routes>
                    </BrowserRouter>
                  </PContext.Provider>
                </TemplateContext.Provider>
              </WSContext.Provider>
            </tabContext.Provider>
          </reqOpenFoldContext.Provider>
        </selectedModuleContext.Provider>
      </DndProvider>
    </selectedFolderContext.Provider>






  );
}

export default App;
