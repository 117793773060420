import React, { useContext, useEffect, useState } from "react";
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import { Box, Grid, Link, Table, Typography } from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import DynamicAutocomplete from "../Lists/dropDownList";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material";
import { DataGrid, GridColDef, GridRowSelectionModel, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { DeleteForever } from "@mui/icons-material";
import App from "../multiTree/App";
import { tpTCContext } from "../../Context/testPlanTCContext";
import { getTestPlanList, updatedTestPlan, saveApiTestPlan } from "../../api/apiCalls";
import { tCase } from "../../Context/testPlanTCContext";
import { tabContext } from "../../Context/TabContext";
import { selectedFolderContext } from "../../Context/SelectedFolderContext";
import { createTestExecution } from "../../api/apiCalls";
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import CustomizedSnackbars from "../snackBar/simpleSnackBar";

export let TestPlanContent = (props) => {
    let { selectedFolder, setSelectedFolder } = useContext(selectedFolderContext)
    const [checked, setChecked] = useState(false);
    const [tcTestCase, setTcTestCases] = useState([]);
    const [addTestDisp, setAddTestDisp] = useState(false);
    const [updatedTestPlanList, setUpdatedTestPlanList] = useState([]);
    const [rowSelectionModel, setRowSelectionModel] =
        React.useState<GridRowSelectionModel>([]);
    const { tabObject, setTabObject } = useContext(tabContext)
    //const [testCases,setTestCases] = useState([]);
    let tptestCases = useContext(tpTCContext).tptestCases
    const { setTpTestCases } = useContext(tpTCContext)
    const [value, setValue] = React.useState('1');
    let [tpId, settpId] = useState<any>();
    const [errorState, setErrorState] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');
    const [severity, setSeverity] = React.useState('')
    const handleError = (errMessage: string, errorSeverity: string) => {
        setErrorState(true);
        setErrorMessage(errMessage);
        setSeverity(errorSeverity);
    };

    const setFalseMessage = async () => {
        setErrorState(false);
        setErrorMessage('');
    }
    /*useEffect(() =>{
         setTpTestCases(props.ntptestCase)
     },[])*/

    const handleChange = (event) => {
        event.stopPropagation();
        setChecked(event.target.checked);
    };
    let saveTestPlan = async () => {
        try {
            //setAddTestDisp(!addTestDisp)
            let reqData = updatedTestPlanList;

            let fReqData = { 'TestCases': reqData, 'parentId': selectedFolder }
            let testPlanDetails = await getTestPlanList(`select=_id&TestPlanId=${props.testPlanId}`)


            if (props.testPlanId.includes('newTestPlan')) {
                let saveData = await saveApiTestPlan(fReqData)

                let testPlanId = saveData['data']['TestPlanId']

                let tabs = tabObject;
                if (!tabs.some(el => el.id === `${testPlanId.id}`)) {
                    let ntabs = [...tabs]
                    for (let ntab of ntabs) {
                        ntab['active'] = false
                    }
                    ntabs = await ntabs.filter((tab) => tab.id !== props.testPlanId)
                    await setTabObject([
                        ...ntabs,
                        {
                            id: `${testPlanId}`,
                            title: `${testPlanId}`,
                            active: true,
                            selectedModule: 3
                        },
                    ]);
                }
            }
            else {
                settpId(testPlanDetails[0]['_id'])
                let testPlanId = testPlanDetails[0]['_id']
                let saveData = await updatedTestPlan(testPlanId, fReqData)

            }
            //alert(JSON.stringify(reqData))
            //alert(JSON.stringify(saveData))
            setTpTestCases(updatedTestPlanList)
            setAddTestDisp(!addTestDisp)
        }
        catch (err) {
            console.error('Error fetching data:', err);
            setErrorState(true)
            setSeverity('error')
            setErrorMessage(err.response.data.error)
        }
    }
    useEffect(() => {
        settpId(props.ntpId)
        setTcTestCases(tptestCases)
    })

    const handleLinkClick = (event, row) => {
        // Prevent checkbox selection
        event.stopPropagation();

        // Handle your custom link behavior (e.g., navigation)
        // ...
    };

    const columns: GridColDef[] = [
        {
            field: 'id', headerName: 'S.No', flex: 1,
        },
        {
            field: 'TestCaseId', headerName: 'Test Case Id', flex: 1, renderCell: (params) => (
                <a href={'#'} onClick={(e) => handleLinkClick(e, params.row)}>{params.row.TestCaseId}</a>
            ),
        },
        { field: 'TestCaseSummary', headerName: 'Summary', flex: 5 },
        { field: 'TestStatus', headerName: 'Test Status', flex: 1 },
        { field: 'AssignedTo', headerName: 'Assigned To', flex: 1 },
    ];
    const columns1: GridColDef[] = [
        {
            field: 'id', headerName: 'S.No', flex: 1,
        },
        {
            field: 'TestExecutionId', headerName: 'Test Execution Id', flex: 1, renderCell: (params) => (
                <a href={'#'} onClick={(e) => handleLinkClick(e, params.row)}>{params.row.TestExecutionId}</a>
            ),
        },

        {
            field: 'AssignedTo', headerName: 'Assigned To', flex: 1, renderCell: (params) => (
                <DynamicAutocomplete moduleId = {3} nteId={params.row._id} userName={params.row.assignedTo}></DynamicAutocomplete>
            ),
        },
    ];
    const handleDeleteClick = (id) => {
        // Handle delete action
        console.log(`Delete clicked for ID ${id}`);
    };
    let updateTPTCs = (updatedList) => {

        setUpdatedTestPlanList(updatedList)
    }
    let navigate = useNavigate();
    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
        setRowSelectionModel([])

    };
    let createTestRun = async () => {
        try
        {
        //alert(JSON.stringify(tcTestCase)) 
        //alert(JSON.stringify(rowSelectionModel))
        let selectedTestCases = []
        for (let cTestCase = 0; cTestCase < tcTestCase.length; cTestCase++) {
            if (rowSelectionModel.includes(cTestCase + 1)) {
                selectedTestCases.push(tcTestCase[cTestCase])
            }
        }

        let sendData = {
            TestCases: selectedTestCases,
            TestPlanId: tpId
        }

        await createTestExecution(sendData)

        navigate('/testexecution');
    }
    catch(err)
    {
        console.error('Error fetching data:', err);
            setErrorState(true)
            setSeverity('error')
            setErrorMessage(err.response.data.error)
    }



    }

    return (

        <Box>
            <CustomizedSnackbars errorMessage={errorMessage} refresh={errorState} closeError={setFalseMessage} severity={severity} />
            <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                            <Tab label="Test Cases" value="1" />
                            <Tab label="Test Executions" value="2" />
                        </TabList>
                    </Box>
                    <TabPanel value="1">
                        <Grid container spacing={3}>
                            <Grid item xs={8}>
                                {!props.testPlanId.includes('newTestPlan') && (<Typography variant="h6">{props.testPlanId}</Typography>)}
                                {props.testPlanId.includes('newTestPlan') && (<Typography variant="h6">New Test Plan</Typography>)}
                            </Grid>
                            {!addTestDisp && (<Grid item xs={4} sx={{ justifyContent: "flex-end", justifySelf: "flex-end" }} >
                                <Button variant="contained" onClick={() => { createTestRun() }} sx={{ bgcolor: '#196db6' }}>Run Tests</Button>
                                <Button variant="contained" onClick={() => { setAddTestDisp(!addTestDisp) }} sx={{ ml: 1, bgcolor: '#1ABC9C' }}>Add Tests</Button>
                            </Grid>)}
                            {addTestDisp && (<Grid container sx={{ justifyContent: "flex-end", justifySelf: "flex-end" }} item xs={3} >
                                <Button variant="contained" onClick={saveTestPlan} sx={{ bgcolor: '#196db6' }}>Save</Button>
                                <Button variant="contained" onClick={() => { setAddTestDisp(!addTestDisp) }} sx={{ ml: 1, bgcolor: 'red' }}>Cancel</Button>
                            </Grid>)}
                            {!addTestDisp && (
                                <>

                                    <Grid item xs={12}>
                                        <DataGrid
                                            columnVisibilityModel={{
                                                id: false,
                                            }}
                                            rows={tcTestCase}
                                            columns={columns}
                                            checkboxSelection
                                            onRowSelectionModelChange={(newRowSelectionModel) => {
                                                setRowSelectionModel(newRowSelectionModel);
                                            }}
                                            rowSelectionModel={rowSelectionModel}
                                            initialState={{
                                                pagination: {
                                                    paginationModel: { page: 0, pageSize: 10 },
                                                },
                                            }}
                                            pageSizeOptions={[10, 20]}
                                        />
                                    </Grid>
                                    <Table>

                                    </Table>
                                </>)}
                        </Grid>
                        {addTestDisp && (
                            <Grid container spacing={3} mt={2}>
                                <App updateTPTCs={updateTPTCs}></App>
                            </Grid>
                        )}
                    </TabPanel>
                    <TabPanel value="2">

                        {<Grid item xs={12}>

                            <DataGrid
                                columnVisibilityModel={{
                                    id: false,
                                }}
                                rows={props.tptestExecution}
                                columns={columns1}
                                
                                onRowSelectionModelChange={(newRowSelectionModel) => {
                                    setRowSelectionModel(newRowSelectionModel);
                                }}
                                rowSelectionModel={rowSelectionModel}
                                initialState={{
                                    pagination: {
                                        paginationModel: { page: 0, pageSize: 10 },
                                    },
                                }}
                                pageSizeOptions={[10, 20]}
                            />
                        </Grid>}
                    </TabPanel>

                </TabContext>
            </Box>
        </Box>
    )

}