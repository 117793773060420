import React, { useContext, useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { NodeModel } from "@minoru/react-dnd-treeview";
import { CustomData } from "./types";
import { TypeIcon } from "./TypeIcon";
import { reqOpenFoldContext } from "../../Context/ReqOpenFoldContext";
import { tObject } from '../../model/requirementsContext'
import { MyContext, MyContextData } from '../../Context/requirementListContext';
import styles from "./CustomNode.module.css";
import { getFolderList, getReqList, getTcList, getTestPlanList,getTeTestCaseDetails } from "../../api/apiCalls";
import { teTCDetailsContext } from "../../Context/teTCDetailsContext";


type Props = {
  moduleId: number;
  node: NodeModel<CustomData>;
  depth: number;
  isOpen: boolean;
  isSelected: boolean;
  onToggle: (id: NodeModel["id"]) => void;
  onSelect: (node: NodeModel) => void;
  testExeId:any;
};



export const CustomNode: React.FC<Props> = (props) => {
 
  let { objects, setObjects } = useContext(MyContext)
  const { setReqFoldObject } = useContext(reqOpenFoldContext)
  const ReqFoldObject = useContext(reqOpenFoldContext)
  let intialOpen = ReqFoldObject.reqOpenFoldObject
  const { droppable, data } = props.node;
  const indent = props.depth * 24;
  const objectExists = (array, key, value) => {
    return array.some(obj => obj[key] === value);
  };

  const handleToggle = async (e: React.MouseEvent) => {
    e.stopPropagation();
    let data = [...objects]
    props.onToggle(props.node.id);
    if (!props.isOpen) {
      
        let nReqFoldList = await getFolderList(props.node.id, props.moduleId)

        for (let reqFold of nReqFoldList) {
          let finalFold = {
            "id": reqFold['id'],
            "parent": reqFold['parentFolder'],
            "droppable": true,
            "text": reqFold['folderName'],
            "favicon": '/description.png'
          }
          if (await objectExists(data, "id", reqFold['id']) == 0) {
            data.push(finalFold)
          }
        }
        if (props.moduleId == 2) {
        console.log(objects)
        let reqList = await getReqList(`select=reqId,parentId,id&parentId=${props.node.id}`)
        console.log(reqList)
        for (let reqFold of reqList) {
          let finalReq = {
            "id": reqFold['reqId'],
            "parent": reqFold['parentId'],
            "droppable": false,
            "text": reqFold['reqId'],
            "data": {
              "fileType": "text"
            },
            "favicon": '/description.png'
          }
          if (await objectExists(data, "id", reqFold['reqId']) == 0) {
            data.push(finalReq)
          }
        }

      }
      if (props.moduleId == 3) {
        console.log(objects)
        let reqList = await getTestPlanList(`select=TestPlanId,parentId,id&parentId=${props.node.id}`)
        console.log(reqList)
        for (let reqFold of reqList) {
          let finalReq = {
            "id": reqFold['TestPlanId'],
            "parent": reqFold['parentId'],
            "droppable": false,
            "text": reqFold['TestPlanId'],
            "data": {
              "fileType": "testPlan"
            },
            "favicon": '/description.png'
          }
          if (await objectExists(data, "id", reqFold['TestPlanId']) == 0) {
            data.push(finalReq)
          }
        }

      }
      if (props.moduleId == 1) {
        console.log(objects)
        let reqList = await getTcList(`select=TestCaseId,parentId,id&parentId=${props.node.id}`)
        console.log(reqList)
        for (let reqFold of reqList) {
          let finalReq = {
            "id": reqFold['TestCaseId'],
            "parent": reqFold['parentId'],
            "droppable": false,
            "text": reqFold['TestCaseId'],
            "data": {
              "fileType": "testCase"
            },
            "favicon": '/description.png'
          }
          if (await objectExists(data, "id", reqFold['TestCaseId']) == 0) {
            data.push(finalReq)
          }
        }

      }

      
      setObjects(data)
      await addInitial(props.node.id)
      
      
    }
    else {
      await removeInitial(props.node.id)
    }

  };
  let removeInitial = (nodeId) => {
    ReqFoldObject.reqOpenFoldObject = ReqFoldObject.reqOpenFoldObject.filter(item => item !== nodeId)
  }

  let addInitial = (nodeId) => {

    setReqFoldObject([
      ...ReqFoldObject.reqOpenFoldObject, nodeId
    ])

  }


  const handleSelect = async () => {
    
    props.onSelect(props.node)
  }

  return (
    
    <div
      className={`tree-node ${styles.root} ${props.isSelected ? styles.isSelected : ""
        }`}
      style={{ paddingInlineStart: indent }}
      onClick={handleSelect}
    >
      <div
        className={`${styles.expandIconWrapper} ${props.isOpen ? styles.isOpen : ""
          }`}
      >
        {props.node.droppable && (
          <div onClick={handleToggle}>
            <ArrowRightIcon />
          </div>
        )}
      </div>
      <div>
        <TypeIcon droppable={droppable} fileType={data?.fileType} />
      </div>
      <div className={styles.labelGridItem}>
        <Typography variant="body2">{props.node.text}</Typography>
      </div>
    </div>
  );
};
