import React, { useState, useContext, useEffect } from "react";
import { DndProvider } from "react-dnd";
import { ThemeProvider, CssBaseline, Box, Button, Grid, Typography, TextField, IconButton } from "@mui/material";
import {
  Tree,
  NodeModel,
  DragLayerMonitorProps,
  MultiBackend,
  getBackendOptions
} from "@minoru/react-dnd-treeview";
import { CustomData } from "./types";
import { CustomNode } from "./CustomNode";
import { CustomDragPreview } from "./CustomDragPreview";
import { theme } from "./theme";
import styles from "./App.module.css";
import { ReqContext } from "../../Context/requirementContext";
import { MyContext } from '../../Context/requirementListContext';
import { tabContext } from "../../Context/TabContext";
import { nTabProperties } from "../../Context/TabContext";
import { reqOpenFoldContext } from "../../Context/ReqOpenFoldContext";
import DescriptionIcon from "@mui/icons-material/Description";
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
import { tcSelectedTCContext } from "../../Context/teSelectedTC";
import { createFolderApi, getFolderList,getTestExecutionList } from "../../api/apiCalls";
import { ElectricalServices } from "@mui/icons-material";
import { selectedFolderContext } from "../../Context/SelectedFolderContext";
import { teTCContext } from "../../Context/teTCContext";
function App(props) {
  let { selectedFolder, setSelectedFolder } = useContext(selectedFolderContext)
  let {teTC,setTeTC} = useContext(teTCContext)
  let [teTCList, setTeTCList] = useState<NodeModel[]>([])
  let [selectedNode1,setSelectedNode1] = useState<any>()

  let selectedNodeObj = useContext(ReqContext)
  let selectedNode = selectedNodeObj.selectedNode
  const { setSelectedNode } = useContext(ReqContext);
  const ReqFoldObject = useContext(reqOpenFoldContext)
  let intialOpen = ReqFoldObject.reqOpenFoldObject
  //console.log('context', objects)
  const [treeData, setTreeData] = useState<NodeModel[]>(teTCList);
  let [nFoldName, setNFoldName] = useState<string>('')
  let [foldTextDisplay, setFoldTextDisplay] = useState<boolean>(false)
  const handleDrop = (newTree: NodeModel[]) => setTreeData(newTree);
  let {teSelectedTC,setTeSelectedTC} = useContext(tcSelectedTCContext)

  const handleSelect = (node: NodeModel) => {
   // alert(JSON.stringify(node.id))
    setSelectedNode1(node);
    setTeSelectedTC(node)
    props.onSelect(node)
  }
  const { setTabObject } = useContext(tabContext)
  let tabs = useContext(tabContext).tabObject
  const objectExists = (array, key, value) => {
    return array.some(obj => obj[key] === value);
  };
  let cancelFoldName = () => {
    setFoldTextDisplay(false)
  }
  let handleChange = (event) => {
    setNFoldName(event.target.value)
  }
  let loadTc = () =>{
    setTreeData(props.teTCList)

  }

  useEffect (() => { loadTc() }, [])

  return (
    <ThemeProvider theme={theme}>
      <div className={styles.app}>
        <div className={styles.current} >
          <Grid container>
            <>
              <Grid item xs={8}>
                <Typography variant={"subtitle1"} color={'#1F618D'}><b>

                 {props.testExeId}
                </b>
                </Typography>

              </Grid>

            </>
          </Grid>


        </div>

        <Box sx={{ 'overflow': 'auto' }}>
          
          <Tree
            
            tree={treeData}
            rootId={0}
            render={(
              node: NodeModel<CustomData>,
              { depth, isOpen, onToggle }
            ) => (
             
              <CustomNode
                moduleId={props.moduleId}
                node={node}
                depth={depth}
                isOpen={isOpen}
                isSelected={node.id === teSelectedTC?.id}
                onToggle={onToggle}
                onSelect={handleSelect}
                testExeId={props.testExeId}

              />
             
            )}
            dragPreviewRender={(
              monitorProps: DragLayerMonitorProps<CustomData>
            ) => <CustomDragPreview monitorProps={monitorProps} />}
            onDrop={handleDrop}
            classes={{
              draggingSource: styles.draggingSource,
              dropTarget: styles.dropTarget
            }}
            initialOpen={intialOpen}
          />
          
        </Box>
      </div>

    </ThemeProvider>
  );
}

export default App;
