import { useState } from "react";
import {
  Tree,
  getBackendOptions,
  MultiBackend,
} from "@minoru/react-dnd-treeview";
import { DndProvider } from "react-dnd";
import GmailTreeView from '../folderStructure/folderStructure'

function ObjectRepo() {
 
  return (
    <GmailTreeView moduleId={3}></GmailTreeView>
  );
}
export default ObjectRepo