import axios from 'axios';
import { config } from 'process';
import keycloak from '../keycloak';
export const BASE_URL = process.env.REACT_APP_API_BASE_URL


let axiosPrivate = axios.create({
    baseURL: BASE_URL
});

axiosPrivate.interceptors.request.use(
    async (config) => {
        if (keycloak.authenticated) {
            // Update the token if it's about to expire
            await keycloak.updateToken(30); // Ensures token is valid for at least 30 seconds
            // Set the Authorization header with the token
            config.headers.Authorization = `Bearer ${keycloak.token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default axiosPrivate
