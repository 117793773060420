import { createContext } from "react";

export type reqOpenFoldProps = {
    reqOpenFoldObject?: (string | number)[];
    setReqFoldObject: React.Dispatch<React.SetStateAction<(string | number)[]>>;
  }
  const defaultValue: reqOpenFoldProps = {
    reqOpenFoldObject: [],
    setReqFoldObject: () => {},
  };  
export const reqOpenFoldContext = createContext<reqOpenFoldProps>(defaultValue);