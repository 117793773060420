import { Stack, Typography } from "@mui/material";
import MainLayout from "../Components/Layout/MainLayout";
import ItemsList from "../Components/Lists/mainList"
import { InputLabel, InputAdornment, IconButton, OutlinedInput, Grid, ThemeProvider, TextField, Button, FormControl, Box } from '@mui/material'

import AddIcon from '@mui/icons-material/Add';
import { Link } from "react-router-dom";
import useAxiosPrivate from "../Hooks/useAxiosPrivate";
import { useContext, useEffect, useState } from "react";
import { WSContext } from "../Context/workSpaceContext";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import GmailTreeView from '../Components/folderStructure/folderStructure'
import ScrollableTabs from "../Components/Tabs/ScrollableTabs";
import ArticleIcon from '@mui/icons-material/Article';
import Label from '@mui/icons-material/Label';
import { MyTCContext, MyTCContextData } from '../Context/tcListContext';
import { MyContext, MyContextData } from '../Context/requirementListContext';
import { tObject } from '../model/requirementsContext'
import { tabContext } from '../Context/TabContext'
import { ReqContext } from "../Context/requirementContext";
import { NodeModel } from "@minoru/react-dnd-treeview";
import { getFolderList, getTestExecutionList } from "../api/apiCalls";
import { selectedModuleContext } from "../Context/SelectedModuleContext";
import { selectedFolderContext } from "../Context/SelectedFolderContext";
import { teTCContext } from "../Context/teTCContext"
import { ListSkeleton } from "../Components/loader/loader";
import CustomizedSnackbars from "../Components/snackBar/simpleSnackBar";

const TestExecution = () => {
    let { selectedModule, setSelectedModule } = useContext(selectedModuleContext);

    let objs = useContext(tabContext);
    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        color: theme.palette.text.secondary,
        height: '90vh'
    }));
    const [objects, setObjects] = useState<tObject[]>([]);
    const [objects1, setObjects1] = useState<tObject[]>([]);
    const [selectedNode, setSelectedNode] = useState<NodeModel>();

    const [selectedFolder, setSelectedFolder] = useState<number>(0);
    const [loader, setLoader] = useState<boolean>(true);
    const [errorState, setErrorState] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [severity, setSeverity] = useState('')
    const handleError = (errMessage: string, errorSeverity: string) => {
        setErrorState(true);
        setErrorMessage(errMessage);
        setSeverity(errorSeverity);
    };

    const setFalseMessage = async () => {
        setErrorState(false);
        setErrorMessage('');
    }

    const contextValue: MyContextData = {
        objects,
        setObjects,
    };

    const contextValue1: MyTCContextData = {
        objects1,
        setObjects1,
    };



    useEffect(() => {

        setSelectedModule(4)

        // Simulating asynchronous data fetching
        const fetchData = async () => {
            try {
                let reqFoldlist = await getFolderList(0, 4)

                let data = [];
                for (let reqFold of reqFoldlist) {
                    let finalFold = {
                        "id": reqFold['id'],
                        "parent": reqFold['parentFolder'],
                        "droppable": true,
                        "text": reqFold['folderName']
                    }
                    data.push(finalFold)
                }
                let reqList = await getTestExecutionList(`select=TestExecutionId,id`)
                for (let reqFold of reqList) {
                    let finalFold = {
                        "id": reqFold['TestExecutionId'],
                        "parent": 0,
                        "droppable": false,
                        "text": reqFold['TestExecutionId'],
                        "data": {
                            "fileType": "testExecution"
                        }
                    }
                    data.push(finalFold)
                }
                setObjects(data);
            } catch (err) {
                console.error('Error fetching data:', err);
                setErrorState(true)
                setSeverity('error')
                setErrorMessage(err.response.data.error)
               
            }
            finally{
                setLoader(false);
            }
        };


        fetchData();


    }, []);




    return (
        <selectedFolderContext.Provider value={{ setSelectedFolder, selectedFolder }}>

            <ReqContext.Provider value={{ selectedNode, setSelectedNode }}>
                <MyContext.Provider value={{ objects, setObjects }}>
                    <MyTCContext.Provider value={{ objects1, setObjects1 }}>
                        <MainLayout>
                        <CustomizedSnackbars errorMessage={errorMessage} refresh={errorState} closeError={setFalseMessage} severity={severity} />
                            <Box sx={{ flexGrow: 1 }}>
                                <Grid container spacing={1} >
                                    <Grid item xs={12} md={2}>
                                        <Item >
                                            <b>Test Execution</b>
                                            {!loader &&
                                                <GmailTreeView moduleId={4}></GmailTreeView>
                                            }
                                            {
                                                loader && <Stack spacing={2} mt={5}>

                                                    <ListSkeleton listsToRender={10} />

                                                </Stack>

                                            }
                                        </Item>

                                    </Grid>
                                    <Grid item xs={12} md={10}>
                                        <Item>
                                            <ScrollableTabs moduleId={4}></ScrollableTabs>
                                        </Item>
                                    </Grid>
                                </Grid>
                            </Box>

                        </MainLayout>
                    </MyTCContext.Provider>
                </MyContext.Provider>
            </ReqContext.Provider>

        </selectedFolderContext.Provider>

    );

}
export default TestExecution