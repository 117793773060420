import exp from "constants";
import  axiosPrivate  from "./axios";

export let getFolderList = async(parentFolder:number | string,moduleId:number) =>{
    let foldListResponse = await axiosPrivate.get(`/api/v1/folders?moduleId=${moduleId}&parentFolder=${parentFolder}`)
    return foldListResponse.data.data
} 

export let getReqList =async (queryParams:string) => {
    let reqList = await axiosPrivate.get(`/api/v1/requirement?${queryParams}`)
    return reqList.data.data
    
}
export let getTcList =async (queryParams:string) => {
    let reqList = await axiosPrivate.get(`/api/v1/testrepo?${queryParams}`)
    return reqList.data.data
    
}

export let getTestPlanList =async (queryParams:string) => {
    let reqList = await axiosPrivate.get(`/api/v1/testplan?${queryParams}`)
    return reqList.data.data
    
}

export let getFields = async(moduleId:number) =>{
    let fields = await axiosPrivate.get(`api/v1/fields?moduleId=${moduleId}&sort=id`)
    return fields.data.data
}

export let updateRequirement = async(updateReq,id) =>{
    let updateRes = await axiosPrivate.put(`/api/v1/requirement/${id}`,updateReq)
    return updateRes.data
}

export let createFolderApi =async (createFoldReq) => {
    let createFold = await axiosPrivate.post(`/api/v1/folders`,createFoldReq)
    if(createFold.status==201)
    {
        return "Success"
      
    }
    else{
        return "There was an issue in creating folder"
    }
   
}

export let saveRequirement = async (createReqPayLoad) =>{
    let createReq = await axiosPrivate.post(`/api/v1/requirement`,createReqPayLoad)
  
    return createReq.data.data
}

export let updateTestCase =  async(updatetc,id) =>{
    let updateTc = await axiosPrivate.put(`/api/v1/testrepo/${id}`,updatetc)
    return updateTc.data
}
export let saveTestCase = async (createTcPayLoad) =>{
    let createTc = await axiosPrivate.post(`/api/v1/testrepo`,createTcPayLoad)
  
    return createTc.data.data
}

export let updatedTestPlan = async (planId,reqData) =>{
    let updateTc = await axiosPrivate.put(`/api/v1/testplan/${planId}`,reqData)
    return updateTc.data
}

export let saveApiTestPlan = async (reqData) =>{
    let updateTc = await axiosPrivate.post(`/api/v1/testplan`,reqData)
    return updateTc.data
}

export let createTestExecution = async(reqData) =>{
    let createTE = await axiosPrivate.post(`/api/v1/testexecution`,reqData)
    return createTE.data
}

export let getTestExecutionList = async(queryParams:string) =>{
    let reqList = await axiosPrivate.get(`/api/v1/testexecution?${queryParams}`)
    return reqList.data.data
}

export let getTeTestCaseDetails = async(tcId,exeId)=>{
    let tcDetails = await axiosPrivate.get(`/api/v1/testexecution/getexecutiontc?tcid=${tcId}&exeid=${exeId}`)
    return tcDetails.data.data
}

export let updateTestSteps = async(tcId,exeId,reqData)=>{
    let updateStep = await axiosPrivate.put(`/api/v1/testexecution/updateteststep?tcid=${tcId}&exeid=${exeId}`,reqData)
    return updateStep.data.data
}

export let uploadAttachment = async(fileFormData) =>{
    let uploadResponse = await axiosPrivate.post(`/api/v1/attachment/upload/file`,fileFormData)
    return uploadResponse
}

export let updateAttachment = async(tcId,exeId,attachmetList) =>{
    let attachmentRespone = await axiosPrivate.put(`/api/v1/testexecution/updateattachment?tcid=${tcId}&exeid=${exeId}`,attachmetList)
    return attachmentRespone
}

export let nDownloadFile = async(attachId) =>{
    let attachResponse = await axiosPrivate.get(`/api/v1/attachment/download/file/${attachId}`)
    return attachResponse
}
export let getCreatedBy = async(userId) =>{
    let response = await axiosPrivate.get(`/api/v1/users/kcUser?id=${userId}`)
    return response.data.data.username
}

export let getUsersList = async(userId) =>{
    let response = await axiosPrivate.get(`/api/v1/users/kcUsers?id=${userId}`)
    return response.data.data
}
export let updateTestExecution =  async(updatetc,id) =>{
    let updateTc = await axiosPrivate.put(`/api/v1/testexecution/${id}`,updatetc)
    return updateTc.data
}

export let getAutomationStepDef =async () => {
    let stepDef = await axiosPrivate.get(`api/v1/automation/getStepDef`)
    return stepDef.data.data
    
}

export let getSavedFeature = async(tcId) =>{
    let featrues = await axiosPrivate.get(`api/v1/automation?TestCaseId=${tcId}&limit=1`)
    return featrues.data.data
}

export let saveFeature = async(tcId,payload) =>{
    let saveFeature = await axiosPrivate.post(`api/v1/automation?TestCaseId=${tcId}`,payload)
    return saveFeature.data
}
export let getDefectList = async(queryParams:string) =>{
    let defectList = await axiosPrivate.get(`api/v1/defects?${queryParams}`)
    return defectList.data.data

}

export let updateDefect = async(defectID,updateBody)=>{
    let updateDefect = await axiosPrivate.put(`api/v1/defects/${defectID}`,updateBody)
    return updateDefect.data
}

export let postDefect = async(payLoad) =>{
    let createDefect = await axiosPrivate.post(`api/v1/defects`,payLoad)
    return createDefect.data.data
}