import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { getFields, getReqList, getTcList, updateRequirement, saveRequirement, updateTestCase, saveTestCase, getTestPlanList, getCreatedBy, getTestExecutionList } from "../../api/apiCalls";
import ReactQuill from 'react-quill';
import { Box, Button, Divider, FormLabel, Grid, Stack, TextField, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import parse from 'html-react-parser';
import AddDeleteTableRows from "./AddDeleteTableRows";
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import { encode, decode } from 'html-entities';
import { ReqContext } from "../../Context/requirementContext";
import 'react-quill/dist/quill.snow.css';
import { TextareaAutosize } from "@mui/base";
import { tabContext } from "../../Context/TabContext";
import { TestPlanContent } from "./TestPlanContent";
import { TestExecutionContent } from "./TestExecutionContent";
import "./TabContent.css"
import { tCase, tpTCContext } from "../../Context/testPlanTCContext";
import { selectedModuleContext } from "../../Context/SelectedModuleContext";
import { TCStepsContext } from "../../Context/testSteps";
import GherkinEditor from "../editor";
import DOMPurify from 'dompurify';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { ListSkeleton } from "../loader/loader";
import { DefectContent } from "./DefectContent"
import CustomizedSnackbars from "../snackBar/simpleSnackBar";

export let TabContent = (props) => {
    //let { selectedModule, setSelectedModule } = useContext(selectedModuleContext);
    let selectedModule: number = props.moduleId
    let selectedNodeObj = useContext(ReqContext).selectedNode
    const [fieldValues, setFieldValues] = React.useState({})
    let [ntpId, setNtpId] = useState();
    let [tcSteps, setTcSteps] = React.useState([]);
    let [teTestCases, setTeTestCases] = useState([]);
    const [fields, setFields] = React.useState([])
    const [edit, setEdit] = React.useState(false)
    const { tabObject, setTabObject } = useContext(tabContext)
    const [tptestCases, setTpTestCases] = useState<tCase[]>([])
    const [tptestExecution, setTpTestExecution] = useState<tCase[]>([])
    const [automateView, setAutomateView] = useState<boolean>(false)
    let [loader, setLoader] = useState<boolean>(true)
    const [errorState, setErrorState] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');

    const [severity, setSeverity] = React.useState('')

    //let tptestCases = useContext(tpTCContext).tptestCases
    //const { setTpTestCases } = useContext(tpTCContext)
    let [ntptestCase, setnpttestcase] = useState([])


    const modules = {
        toolbar: [
            [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
            [{ size: ['small', false, 'large', 'huge'] }],  // Font size dropdown
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
            ['clean']
        ],
    };
    const handleError = (errMessage: string, errorSeverity: string) => {
        setErrorState(true);
        setErrorMessage(errMessage);
        setSeverity(errorSeverity);
    };

    const setFalseMessage = async () => {
        setErrorState(false);
        setErrorMessage('');
    }

    const formats = [
        'header', 'font', 'size',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',

    ];

    useEffect(() => {

        // Simulating asynchronous data fetching
        const fetchData = async () => {

            try {

                if (selectedModule != 3) {
                    let reqFields = await getFields(selectedModule)
                    console.log(reqFields)
                    setFields(reqFields)
                }
                if (selectedModule == 4) {
                    let reqFields = await getFields(1)
                    console.log(reqFields)
                    setFields(reqFields)
                }
                if (selectedModule === 2) {
                    let defFields = ''
                    if (!props.tabid.includes('newReq')) {
                        if (!localStorage.getItem(props.tabid)) {
                            let reqValues = await getReqList(`reqId=${props.tabid}`)
                            if (reqValues[0]['createdBy']) {
                                reqValues[0]['createdBy'] = await getCreatedBy(reqValues[0]['createdBy'])

                            }
                            if (reqValues[0]['updatedBy']) {
                                reqValues[0]['updatedBy'] = await getCreatedBy(reqValues[0]['updatedBy'])

                            }


                            defFields = Object.assign(reqValues[0], reqValues[0]['dynamicFields'])
                        }

                        if (localStorage.getItem(props.tabid)) {
                            setEdit(true)
                            setFieldValues(JSON.parse(localStorage.getItem(props.tabid)))
                        }
                        else {
                            setFieldValues(defFields)
                        }

                        //console.log(defFields)
                    }
                    else {
                        if (localStorage.getItem(props.tabid)) {
                            setFieldValues(JSON.parse(localStorage.getItem(props.tabid)))
                        }
                        else {
                            setFieldValues({})
                        }
                        setEdit(true)
                    }
                }

                if (selectedModule === 1) {
                    let defFields = ''
                    let testSteps = []
                    if (!props.tabid.includes('newTestCase')) {
                        if (!localStorage.getItem(props.tabid)) {
                            let tcValues = await getTcList(`TestCaseId=${props.tabid}`)
                            if (tcValues[0]['createdBy']) {
                                tcValues[0]['createdBy'] = await getCreatedBy(tcValues[0]['createdBy'])

                            }
                            if (tcValues[0]['updatedBy']) {
                                tcValues[0]['updatedBy'] = await getCreatedBy(tcValues[0]['updatedBy'])

                            }
                            testSteps = tcValues[0]['TestSteps']
                            defFields = Object.assign(tcValues[0], tcValues[0]['dynamicFields'])
                        }
                        if (localStorage.getItem(props.tabid)) {
                            setEdit(true)
                            setFieldValues(JSON.parse(localStorage.getItem(props.tabid)))
                        }
                        else {
                            setFieldValues(defFields)
                        }


                        if (testSteps.length == 0 || testSteps == undefined) {
                            testSteps = [{ "testStep": "", "Expected": "" }]
                        }
                        if (localStorage.getItem(props.tabid + '_step')) {
                            setTcSteps(JSON.parse(localStorage.getItem(props.tabid + '_step')))
                        }
                        else {
                            setTcSteps(testSteps)
                        }


                    }
                    else {
                        let testSteps = [{ "testStep": "", "Expected": "" }]

                        if (localStorage.getItem(props.tabid + '_step')) {
                            setTcSteps(JSON.parse(localStorage.getItem(props.tabid + '_step')))
                        }
                        else {
                            setTcSteps(testSteps)
                        }
                        if (localStorage.getItem(props.tabid)) {
                            setFieldValues(JSON.parse(localStorage.getItem(props.tabid)))
                        }
                        else {
                            setFieldValues({})
                        }
                        setEdit(true)
                    }

                }
            }
            catch (err) {
                setErrorState(true)
                setSeverity('error')
                setErrorMessage(err.response.data.error)
            }
            finally {
                setLoader(false)
            }

        }
        fetchData();
    }, []);


    useEffect(() => {
        if (edit) {
            localStorage.setItem(props.tabid, JSON.stringify(fieldValues));
        }
    }, [fieldValues]);
    useEffect(() => {
        if (edit) {
            localStorage.setItem(props.tabid + '_step', JSON.stringify(tcSteps));
        }
    }, [tcSteps]);

    const fetchData = useCallback(async () => {
        try {

            if (selectedModule === 3) {
                const testPlanDetails = await getTestPlanList(`select=_id,TestPlanId,TestCases&TestPlanId=${props.tabid}`);
                const tcDetails = testPlanDetails[0]['TestCases'];
                const updatedTcDetails = tcDetails.map((tc, index) => ({ ...tc, id: index + 1 }));
                setNtpId(testPlanDetails[0]['_id'])
                await setTpTestCases(updatedTcDetails);
                const TestExecutionList = await getTestExecutionList(`select=_id,id,TestExecutionId,TestPlanId,assignedTo&TestPlanId=${testPlanDetails[0]['_id']}`)
                for (let cTE = 0; cTE < TestExecutionList.length; cTE++) {
                    if (TestExecutionList[cTE]['assignedTo']) {
                        TestExecutionList[cTE]['assignedTo'] = await getCreatedBy(TestExecutionList[cTE]['assignedTo'])
                    }
                }
                setTpTestExecution(TestExecutionList)
                //alert(JSON.stringify(TestExecutionList))

            }
        } catch (err) {
            console.error('Error fetching data:', err);
            setErrorState(true)
            setSeverity('error')
            setErrorMessage(err.response.data.error)
            //setLoader(false)
        }
        finally {
            setLoader(false)
        }

    }, []);
    useEffect(() => { fetchData() }, [])
    let checkIfEncoded = (str) => {
        const htmlEntities = /&[a-zA-Z0-9#]{2,6};/g;
        return htmlEntities.test(str);
    }
    let handleEdit = () => {
        setEdit(true)

    }
    let handleSave = async () => {
        try {
            setLoader(true)
            localStorage.removeItem(props.tabid + '_step')
            localStorage.removeItem(props.tabid)
            let updateData = {}
            updateData['dynamicFields'] = {}


            for (let field of fields) {
                if (!field['isDefault']) {
                    updateData['dynamicFields'][field['fieldName']] = fieldValues[field['fieldName']]
                }
                else {
                    updateData[field['fieldName']] = fieldValues[field['fieldName']]
                }
            }
            // console.log(JSON.stringify(updateData))
            if (!props.tabid.includes('newReq') && !props.tabid.includes('newTestCase')) {
                let updateRequirementResp = {}
                if (selectedModule == 2) {
                    updateData['requirementSummary'] = await encode(fieldValues['requirementSummary'], { level: 'html5' })
                    if (!fieldValues['requirementDescription']) {
                        updateData['requirementDescription'] = await encode(fieldValues['requirementDescription'], { level: 'html5' })
                    }

                    updateRequirementResp = await updateRequirement(updateData, fieldValues['_id'])
                }
                if (selectedModule == 1) {
                    //updateData['TestCaseSummary'] = encode(fieldValues['TestCaseSummary'], { level: 'html5' })
                    //updateData['TestCaseDescription'] = encode(fieldValues['TestCaseDescription'], { level: 'html5' })
                    updateData['TestSteps'] = tcSteps;
                    updateRequirementResp = await updateTestCase(updateData, fieldValues['_id'])
                }

                //let updateRequirementResp = updateRequirement(updateData, fieldValues['_id'])
            }
            else {
                let parentId = 0
                if (selectedNodeObj) {
                    if (selectedNodeObj.droppable) {
                        parentId = typeof selectedNodeObj.id === "number" ? selectedNodeObj.id : parseInt(selectedNodeObj.id as string, 10);
                    }
                    else {
                        parentId = typeof selectedNodeObj.parent === "number" ? selectedNodeObj.parent : parseInt(selectedNodeObj.parent as string, 10);
                    }
                }

                updateData['parentId'] = parentId

                if (selectedModule == 2) {

                    updateData['requirementSummary'] = await encode(fieldValues['requirementSummary'], { level: 'html5' })
                    updateData['requirementDescription'] = await encode(fieldValues['requirementDescription'], { level: 'html5' })

                    let savedata = await saveRequirement(updateData)


                    //let defFields = Object.assign(savedata, savedata['dynamicFields'])
                    //await props.closeTab(props.tabid)  
                    let tabs = tabObject;

                    if (!tabs.some(el => el.id === `${savedata.id}`)) {
                        let ntabs = [...tabs]
                        for (let ntab of ntabs) {
                            ntab['active'] = false
                        }
                        ntabs = await ntabs.filter((tab) => tab.id !== props.tabid)
                        await setTabObject([
                            ...ntabs,
                            {
                                id: `${savedata.reqId}`,
                                title: `${savedata.reqId}`,
                                active: true,
                                selectedModule: selectedModule
                            },
                        ]);
                    }
                }
                if (selectedModule == 1) {
                    updateData['TestCaseSummary'] = await encode(fieldValues['TestCaseSummary'], { level: 'html5' })
                    updateData['TestCaseDescription'] = await encode(fieldValues['TestCaseDescription'], { level: 'html5' })
                    updateData['TestSteps'] = tcSteps;
                    let savedata = await saveTestCase(updateData)
                    let tabs = tabObject;
                    if (!tabs.some(el => el.id === `${savedata.id}`)) {
                        let ntabs = [...tabs]
                        for (let ntab of ntabs) {
                            ntab['active'] = false
                        }
                        ntabs = await ntabs.filter((tab) => tab.id !== props.tabid)
                        await setTabObject([
                            ...ntabs,
                            {
                                id: `${savedata.TestCaseId}`,
                                title: `${savedata.TestCaseId}`,
                                active: true,
                                selectedModule: selectedModule
                            },
                        ]);
                    }

                }
            }
        } catch (err) {
            setErrorState(true)
            setSeverity('error')
            setErrorMessage(err.response.data.error)
        }
        finally {
            setLoader(false)
        }
        setEdit(false)
    }
    let handleAutomate = () => {
        setAutomateView(true)
    }
    let onButtonClick = () => {
        setAutomateView(false)
    }

    let cancelEdit = () => {
        localStorage.removeItem(props.tabid)
        localStorage.removeItem(props.tabid + '_step')
        setEdit(false)
    }
    let handleFieldChange = (fieldName, nFieldVal,editor?) => {
        if(editor)
        {
            nFieldVal = JSON.stringify(editor.getContents())
        }

        setFieldValues({ ...fieldValues, [fieldName]: nFieldVal })

    }

    return (

        <TCStepsContext.Provider value={{ tcSteps, setTcSteps }}>
            <tpTCContext.Provider value={{ tptestCases, setTpTestCases }}>
                <CustomizedSnackbars errorMessage={errorMessage} refresh={errorState} closeError={setFalseMessage} severity={severity} />
                {loader && <Grid container><Grid item xs={6}><Stack spacing={2} alignContent={'center'} mt={12} ml={4} width={'90%'} > <ListSkeleton listsToRender={10}></ListSkeleton></Stack></Grid><Grid item xs={6}><Stack spacing={2} alignContent={'center'} mt={12} width={'90%'} > <ListSkeleton listsToRender={10}></ListSkeleton></Stack></Grid></Grid>}

                {!loader &&

                    <>
                        {!automateView &&
                            <Box sx={{ overflow: "auto", height: '80vh' }}>
                                {selectedModule != 3 && selectedModule != 4 && selectedModule != 5 && (
                                    <>
                                        <Grid container mt={2}>
                                            {selectedModule == 2 &&
                                                <Grid item xs={8}>
                                                    {!edit ? (<Typography variant="h6" color={'#1F618D'}>{fieldValues['reqId']} : {fieldValues['requirementSummary']} </Typography>) : <><Typography variant="h6" color={'#1F618D'}><b>{fieldValues['reqId']}</b></Typography></>}
                                                </Grid>
                                            }
                                            {selectedModule == 1 &&
                                                <Grid item xs={8}>
                                                    {!edit ? (<Typography variant="h6" color={'#1F618D'}>{fieldValues['TestCaseId']} : {fieldValues['TestCaseSummary']} </Typography>) : <><Typography variant="h6" color={'#1F618D'}><b>{fieldValues['TestCaseId']}</b></Typography></>}
                                                </Grid>
                                            }

                                            <Grid item xs={4} justifyContent="flex-end">
                                                <Box display="flex" justifyContent="flex-end">
                                                    {selectedModule == 1 &&
                                                        <Button variant="contained" disabled={edit} onClick={handleAutomate} sx={{ bgcolor: 'dark blue' }}><SettingsSuggestIcon></SettingsSuggestIcon>Automate</Button>}
                                                    <Button variant="contained" disabled={edit} onClick={handleEdit} sx={{ ml: 1, bgcolor: '#196db6' }}><EditIcon></EditIcon></Button>
                                                    <Button variant="contained" disabled={!edit} onClick={handleSave} sx={{ ml: 1, bgcolor: '#1ABC9C' }}><SaveIcon></SaveIcon></Button>
                                                    <Button variant="contained" disabled={!edit} onClick={cancelEdit} sx={{ ml: 1, bgcolor: '#ef4050' }}><CloseIcon></CloseIcon></Button>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                {edit && selectedModule == 2 && (<><FormLabel><b>Summary:</b></FormLabel><TextField
                                                    value={decode(fieldValues['requirementSummary'], { level: "html5" })}
                                                    minRows={2}
                                                    sx={{ mt: 1 }}
                                                    fullWidth
                                                    size="small"
                                                    id={'Summary'}
                                                    onChange={(e) => handleFieldChange('requirementSummary', e.target.value)}
                                                /></>)}
                                                {edit && selectedModule == 1 && (<><FormLabel><b>Summary:</b></FormLabel><TextField
                                                    value={decode(fieldValues['TestCaseSummary'], { level: "html5" })}
                                                    minRows={2}
                                                    sx={{ mt: 1 }}
                                                    fullWidth
                                                    size="small"
                                                    id={'Summary'}
                                                    onChange={(e) => handleFieldChange('TestCaseSummary', e.target.value)}
                                                /></>)}
                                            </Grid>
                                            {!edit && selectedModule != 5 && <>
                                                <Grid container sx={{ border: 0.1, borderRadius: 2, p: 1 }} mt={2}>


                                                    <Grid item xs={6} >
                                                        <FormLabel focused>Created By : </FormLabel>
                                                        <span>{fieldValues['createdBy']}</span>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <FormLabel focused>Created At : </FormLabel>
                                                        <span>{fieldValues['createdAt']}</span>
                                                    </Grid>

                                                    <Grid item xs={6}>
                                                        <FormLabel focused>Updated By : </FormLabel>
                                                        <span>{fieldValues['updatedBy']}</span>

                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <FormLabel focused>Updated At : </FormLabel>
                                                        <span>{fieldValues['updatedAt']}</span>
                                                    </Grid>

                                                </Grid>
                                            </>
                                            }
                                            <Grid container mt={1} spacing={1}>
                                                {fields.map((field, index) => (
                                                    <Grid item xs={field.fieldType === 'TextArea' || field.fieldType === 'Editor' ? 12 : 6} key={index}>
                                                        {field.isDisplayed && field.fieldName != "requirementSummary" && field.fieldName != "TestCaseSummary" && field.fieldName != "Expected" && field.fieldName != "testStep" && (<FormLabel sx={{ asterisk: "red" }} required={field.isMandatory} htmlFor={field.displayName}>{field.displayName}</FormLabel>)}

                                                        {edit && field.fieldType === 'TextArea' && field.fieldName != "requirementSummary" && field.fieldName != "TestCaseSummary" && field.fieldName != "Expected" && field.fieldName != "testStep" && (
                                                            <>
                                                                <TextField
                                                                    value={decode(fieldValues[field.fieldName], { level: "html5" })}
                                                                    minRows={2}
                                                                    multiline
                                                                    fullWidth
                                                                    size="small"
                                                                    id={field.displayName}
                                                                    onChange={(e) => handleFieldChange(field.fieldName, e.target.value)}
                                                                />
                                                                <Divider sx={{ backgroundColor: '#244855', height: 1 }} orientation="horizontal" flexItem />

                                                            </>



                                                        )}

                                                        {edit && field.fieldType === "Editor" && (
                                                            <>
                                                                <ReactQuill modules={modules}
                                                                    formats={formats} theme="snow" onChange={(e,delta,sources,editor) => handleFieldChange(field.fieldName, e,editor)} value={fieldValues[field.fieldName]?JSON.parse(fieldValues[field.fieldName]):''} />

                                                            </>

                                                        )}
                                                        {edit && field.fieldType === "Text" && (

                                                            <TextField value={fieldValues[field.fieldName]} id={field.displayName} onChange={(e) => handleFieldChange(field.fieldName, e.target.value)} variant="outlined" size="small" fullWidth></TextField>

                                                        )}
                                                        {edit && field.fieldType === "DropDown" && (

                                                            <Select fullWidth size="small" onChange={(e) => handleFieldChange(field.fieldName, e.target.value)} value={fieldValues[field.fieldName]}>
                                                                {field.defaultValue.map((dropDownValue, index) => (

                                                                    <MenuItem value={dropDownValue}>{dropDownValue}</MenuItem>


                                                                ))}
                                                            </Select>
                                                        )}


                                                        {!edit && field.fieldType == "Editor" && <> <Box sx={{ border: 0.1, borderRadius: 2, p: 1 }}><ReactQuill theme="bubble" readOnly value={fieldValues[field.fieldName]?JSON.parse(fieldValues[field.fieldName]):''} /></Box></>}
                                                        {field.isDisplayed && !edit && field.fieldName != "requirementSummary" && field.fieldName != "TestCaseSummary" && field.fieldType != "Editor" && field.fieldName != "Expected" && field.fieldName != "testStep" && <> <TextField disabled fullWidth size="small" sx={{
                                                            'bgcolor': '#f2f4f4', "& .MuiInputBase-input.Mui-disabled": {
                                                                WebkitTextFillColor: "#616a6b",
                                                            },
                                                        }} value={fieldValues[field.fieldName]}></TextField>
                                                        </>}
                                                        {/* Add other field types as needed */}

                                                    </Grid>
                                                ))}
                                            </Grid>
                                            {selectedModule == 1 &&
                                                <Grid item xs={12} mt={4}>
                                                    <Divider orientation="horizontal" flexItem />
                                                    <AddDeleteTableRows rows={tcSteps} editable={edit} module={1}></AddDeleteTableRows>
                                                </Grid>
                                            }
                                        </Grid>
                                    </>
                                )}
                                {selectedModule == 3 && (
                                    <>
                                        <TestPlanContent ntpId={ntpId} tptestExecution={tptestExecution} testPlanId={props.tabid} ></TestPlanContent>
                                    </>

                                )}
                                {selectedModule == 4 && (
                                    <>
                                        <TestExecutionContent testExeId={props.tabid} tcFields={fields} ></TestExecutionContent>
                                    </>

                                )}
                                {selectedModule == 5 && (
                                    <>
                                        <DefectContent defectId={props.tabid} defFields={fields} ></DefectContent>
                                    </>

                                )}


                            </Box>

                        }
                        {automateView &&
                            <>
                                <Box height={'80vh'}>
                                    <GherkinEditor testCaseId={props.tabid} onButtonClick={onButtonClick}></GherkinEditor>
                                </Box>
                            </>
                        }

                    </>
                }
            </tpTCContext.Provider>
        </TCStepsContext.Provider>
    )
}