import React, { useEffect, createContext, useContext, useState, ReactNode, Dispatch, SetStateAction } from 'react';

// Define the FileData type
export type FileData = {
    fileType: string;
};

// Define the tObject type
export type TObject = {
    id: number;
    parent: number;
    droppable: boolean;
    text: string;
    data?: FileData;
};

// Define the context data type
export type MyContextData = {
    objects: TObject[];
    setObjects: Dispatch<SetStateAction<TObject[]>>;
};

// Create the context with an initial value
export const MyContext = createContext<MyContextData | undefined>(undefined);

// Define the type for the provider props
type MyContextProviderProps = {
    children: ReactNode;
};
// Run the effect only once on mount


// Create a provider component


// Create a custom hook to use the context in components
export const useMyContext = () => {
    const context = useContext(MyContext);
    if (!context) {
        throw new Error('useMyContext must be used within a MyContextProvider');
    }
    return context;
};
